import DetailsSection from "../DetailsSection/DetailsSection";
import PlanSection from "../PlanSection/PlanSection";
import SubscriptionSection from "../SubscriptionSection/SubscriptionSection";
import { FullPageLoader } from "../../../../components/Loader";
import Button from "../../../../components/Button/Button";
import ErrorPortal from "../../../../components/ErrorPortal/ErrorPortal";
import {
  DeleteUserModalContent,
  BlockUserModalContent,
  UnblockUserModalContent,
} from "../ModalContent";
import HomeButton from "../../../../components/HomeButton";
import useUser from "./User.hook";

export default function User() {
  const { states, setters, handlers } = useUser();
  const {
    data,
    status,
    error,
    showDeleteUserModal,
    showBlockUserModal,
    showUnblockUserModal,
    params,
    ipToBlock,
    domainToBlock,
  } = states;
  const {
    setShowDeleteUserModal,
    setShowBlockUserModal,
    setShowUnblockUserModal,
  } = setters;
  const {
    handleUnblock,
    handleBlock,
    handleRestoreUser,
    handleDelete,
    handleCheckboxChange,
  } = handlers;

  if (status === "loading") {
    return <FullPageLoader />;
  }

  if (error) {
    return <ErrorPortal title="User Details" message={error.message} />;
  }

  return (
    <>
      <div id="Details">
        <DeleteUserModalContent
          isOpen={showDeleteUserModal}
          setIsOpen={setShowDeleteUserModal}
          handleDeleteUser={handleDelete}
        >
          Are you sure you want to delete:{" "}
          <span style={{ fontWeight: "bold" }}>{data.user.email}</span>?
        </DeleteUserModalContent>
        <BlockUserModalContent
          isOpen={showBlockUserModal}
          setIsOpen={setShowBlockUserModal}
          handleBlockUser={handleBlock}
        >
          Are you sure you want to block:{" "}
          <span style={{ fontWeight: "bold" }}>{data.user.email}</span>?
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="ip"
              checked={ipToBlock}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ip">IP</label>
          </div>
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="domain"
              checked={domainToBlock}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={"domain"}>Domain</label>
          </div>
        </BlockUserModalContent>
        <UnblockUserModalContent
          isOpen={showUnblockUserModal}
          setIsOpen={setShowUnblockUserModal}
          handleUnblockUser={handleUnblock}
        >
          Are you sure you want to unblock:{" "}
          <span style={{ fontWeight: "bold" }}>{data.user.email}</span>?
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="ip"
              checked={ipToBlock}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ip">IP</label>
          </div>
          <div>
            <input
              type="checkbox"
              className="checkbox"
              id="domain"
              checked={domainToBlock}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={"domain"}>Domain</label>
          </div>
        </UnblockUserModalContent>
        <div className="detailsHeader">
          <HomeButton />
          <div className="detailsHeaderButtons">
            {data.user.isDeleted ? (
              <Button handleClick={handleRestoreUser} className={"btn"}>
                Restore Account
              </Button>
            ) : (
              <Button
                handleClick={() => setShowDeleteUserModal(true)}
                className={"btn dangerBtn"}
              >
                Delete Account
              </Button>
            )}
            {data.user.isBlocked ? (
              <Button handleClick={() => setShowUnblockUserModal(true)}>
                Unblock Account
              </Button>
            ) : (
              <Button
                handleClick={() => setShowBlockUserModal(true)}
                className={"btn warningBtn"}
              >
                Block Account
              </Button>
            )}
            <Button
              link={`/PersonalCreditsUsage/?email=${params.email}`}
              className={"btn"}
            >
              Personal Credits History
            </Button>
            {data.organizationId && (
              <Button link={`/Details/OrganizationDetails/${data.email}`}>
                Go to Organization
              </Button>
            )}
          </div>
        </div>
        <DetailsSection
          data={data}
          remainingCreditsTable={{
            personalEmailCredits: data.personalCredits.personalEmailCredits,
            phoneCredits: data.personalCredits.phoneCredits,
            workEmailCredits: data.personalCredits.workEmailCredits,
          }}
          user
        >
          User Details
        </DetailsSection>
        {data.plan && <PlanSection data={data.plan}>Current Plan</PlanSection>}
        {data.pending && data.pending.plan && (
          <PlanSection data={data.pending.plan}>Pending Plan</PlanSection>
        )}
        {data.subscription && (
          <SubscriptionSection data={data.subscription}>
            Current Subscription
          </SubscriptionSection>
        )}
        {data.pending && data.pending.subscription && (
          <SubscriptionSection data={data.pending.subscription}>
            Pending Subscription
          </SubscriptionSection>
        )}
      </div>
    </>
  );
}
