import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/Modal";
import Button from "../../../../../components/Button/Button";
import { setInvitationToCancel } from "../../InvitationsList/cancelInvitationSlice";

export function CancelInvitationModalContent({
  isOpen,
  handleCancelInvitation,
}) {
  const dispatch = useDispatch();
  const invitationToCancelState = useSelector(
    (state) => state.cancelInvitation
  );
  const closeModal = () =>
    dispatch(
      setInvitationToCancel({
        email: "",
        showCancelInvitationModal: false,
      })
    );

  return (
    <Modal open={isOpen} handleClose={closeModal}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {`Cancel the invitation for: ${invitationToCancelState.inviteeEmail} ?`}
      </h2>
      <div className="modalButtons">
        <Button handleClick={closeModal} className={"btn"} width={80}>
          No
        </Button>
        <Button
          handleClick={() => {
            handleCancelInvitation.mutateAsync({
              inviteeEmail: invitationToCancelState.inviteeEmail,
              email: invitationToCancelState.organizationOwner,
              organizationId: invitationToCancelState.orgId,
            });
            closeModal();
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}
