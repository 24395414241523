import { createSlice } from "@reduxjs/toolkit";

export const orgIdSlice = createSlice({
  name: "orgId",
  initialState: {},
  reducers: {
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
  },
});

export const { setOrgId } = orgIdSlice.actions;

export default orgIdSlice.reducer;
