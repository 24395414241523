import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import { setMember } from "../deleteMemberSlice";

export function UnsubscribeModalContent({
  children,
  isOpen,
  setIsOpen,
  handleUnsubscribe,
  organizationId,
}) {
  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {children}
      </h2>
      <div className="modalButtons">
        <Button
          handleClick={() => setIsOpen(false)}
          className={"btn"}
          width={80}
        >
          No
        </Button>
        <Button
          handleClick={() => {
            handleUnsubscribe(organizationId);
            setIsOpen(false);
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export function DeleteMemberModalContent({ isOpen, handleDeleteMember }) {
  const dispatch = useDispatch();
  const memberToDelete = useSelector((state) => state.deleteMember);
  const closeModal = () =>
    dispatch(
      setMember({
        email: "",
        organizationId: "",
        showDeleteMemberModal: false,
      })
    );

  return (
    <Modal open={isOpen} handleClose={closeModal}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {`You are about to DELETE: ${memberToDelete.member}`}
      </h2>
      <div className="modalButtons">
        <Button handleClick={closeModal} className={"btn"} width={80}>
          No
        </Button>
        <Button
          handleClick={() => {
            handleDeleteMember.mutateAsync({
              email: memberToDelete.member,
              organizationId: memberToDelete.orgId,
            });
            closeModal();
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export function DeleteUserModalContent({
  children,
  isOpen,
  setIsOpen,
  handleDeleteUser,
}) {
  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {children}
      </h2>
      <div className="modalButtons">
        <Button
          handleClick={() => setIsOpen(false)}
          className={"btn"}
          width={80}
        >
          No
        </Button>
        <Button
          handleClick={() => {
            handleDeleteUser();
            setIsOpen(false);
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export function BlockUserModalContent({
  children,
  isOpen,
  setIsOpen,
  handleBlockUser,
}) {
  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {children}
      </h2>
      <div className="modalButtons">
        <Button
          handleClick={() => setIsOpen(false)}
          className={"btn"}
          width={80}
        >
          No
        </Button>
        <Button
          handleClick={() => {
            handleBlockUser();
            setIsOpen(false);
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export function UnblockUserModalContent({
  children,
  isOpen,
  setIsOpen,
  handleUnblockUser,
}) {
  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {children}
      </h2>
      <div className="modalButtons">
        <Button
          handleClick={() => setIsOpen(false)}
          className={"btn"}
          width={80}
        >
          No
        </Button>
        <Button
          handleClick={() => {
            handleUnblockUser();
            setIsOpen(false);
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export function SuspendPlanModalContent({
  children,
  isOpen,
  setIsOpen,
  handleSuspendPlan,
}) {
  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
      <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
        {children}
      </h2>
      <div className="modalButtons">
        <Button
          handleClick={() => setIsOpen(false)}
          className={"btn"}
          width={80}
        >
          No
        </Button>
        <Button
          handleClick={() => {
            handleSuspendPlan();
            setIsOpen(false);
          }}
          className={"btn dangerBtn"}
          width={80}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
}
