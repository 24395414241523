import "./Permission.css";

export default function Permissions({ permissionSet, data, index }) {
  const { name: permission, actions, exists } = permissionSet;
  const containerClassName =
    index % 2 === 0
      ? "permissionsContainer"
      : "permissionsContainer darkBackground";

  if (permission === "PublicApi" || permission === "HideGenericsEmails") {
    if (exists) {
      if (permission === "PublicApi") {
        return (
          <div>
            <div className={containerClassName}>
              <h3>{permission}:</h3>
              <span key={permission} className="tag">
                Yes
              </span>
              <h3 style={{ marginLeft: "20px" }}>Limits:</h3>
              <span className="tag">{`${data.publicApiLimits.day} days`}</span>
              <span className="tag">{`${data.publicApiLimits.hour} hours`}</span>
              <span className="tag">{`${data.publicApiLimits.minute} minutes`}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className={containerClassName}>
              <h3>{permission}:</h3>
              <span key={permission} className="tag">
                Yes
              </span>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className={containerClassName}>
            <h3>{permission}:</h3>
            <span key={permission} className="tag">
              No
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className={containerClassName}>
        <h3>{permission}:</h3>
        {actions.map((a) => (
          <span key={permission + a} className="tag">
            {a}
          </span>
        ))}
      </div>
    </div>
  );
}
