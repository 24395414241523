import { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  convertIdOrEmail,
  notifyLoading,
  notifyUpdate,
  notifyError,
} from "../../../../utilities/utils";
import { getOrgMembers, updateMember } from "../../../../services/api/api";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button/Button";
import Table from "../../../../components/Table/Table";
import Loader from "../../../../components/Loader";

export default function MembersList({ maxAdmins }) {
  let toastId;
  const membersRef = useRef();
  const searchInputRef = useRef();
  const params = useParams();
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [newRole, setNewRole] = useState();
  const [updateEmailRole, setUpdateEmailRole] = useState();
  const [memberIndex, setMemberIndex] = useState();

  const [key] = Object.keys(params);
  const emailOrId = convertIdOrEmail(params[key]);
  const { data, status } = useQuery(
    ["orgDetailsMembers", params, currentPage, searchInput],
    () =>
      getOrgMembers({
        ...emailOrId,
        search: searchInput,
        page: currentPage,
        resultPerPage: 10,
      })
  );

  const updateMemberMutation = useMutation(updateMember, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetailsMembers");
      res
        ? notifyUpdate(toastId, "Updated Role Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update Role", "error");
    },
  });

  const updateRole = () => {
    if (newRole === "admin") {
      updateMemberMutation.mutate({
        email: updateEmailRole,
        infos: {
          type: newRole,
          permissions: [
            ...data.members[memberIndex].permissions,
            { actions: [], name: "Administration" },
          ],
          limit: {
            legacyCredits: 10000,
            workEmailCredits: 10000,
            personalEmailCredits: 10000,
            phoneCredits: 10000,
            exportCredits: 10000,
          },
        },
      });
      setIsOpen(false);
    } else {
      if (data.totalAdmins === 1) {
        notifyError("At least one Admin is Required");
        setIsOpen(false);
      } else {
        updateMemberMutation.mutate({
          email: updateEmailRole,
          infos: {
            type: newRole,
            permissions: data.members[memberIndex].permissions.filter(
              (permission) => permission.name !== "Administration"
            ),
          },
        });
        setIsOpen(false);
      }
    }
  };

  const scrollToElement = () => {
    membersRef.current
      ? membersRef.current.scrollIntoView({ behavior: "smooth" })
      : null;
  };

  const onPageChange = (page) => {
    navigate(`?page=${page}${searchInput ? `&search=${searchInput}` : ""}`);
    setCurrentPage(page);
    scrollToElement();
  };

  const handleSearch = () => {
    scrollToElement();
    navigate(`?page=1&search=${searchInputRef.current.value}`);
    setSearchInput(searchInputRef.current.value);
  };

  const changeRole = (index) => {
    setMemberIndex(index);
    const email = data.members[index].email;
    const role = data.members[index].type;
    role === "admin" || role === "owner"
      ? setNewRole("member")
      : setNewRole("admin");
    setUpdateEmailRole(email);
    setIsOpen(true);
  };

  useEffect(() => {
    const page = parseInt(queryParams.get("page"));
    const search = queryParams.get("search");

    if (page && page !== currentPage) {
      setCurrentPage(page);
      scrollToElement();
    }
    if (page && page === currentPage) {
      scrollToElement();
    }
    if (!page) {
      setCurrentPage(1);
    }
    if (!search) {
      setSearchInput("");
    }
  }, [currentPage, queryParams]);

  if (status === "loading") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div ref={membersRef}>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <h2 style={{ fontWeight: 600, padding: 30, fontSize: "1.2rem" }}>
          {newRole === "member" ? "Downgraded to Member?" : "Promote to Admin?"}
        </h2>
        <div className="modalButtons">
          <Button
            handleClick={() => setIsOpen(false)}
            className={"btn"}
            width={80}
          >
            No
          </Button>
          <Button
            handleClick={() => updateRole()}
            className={"btn dangerBtn"}
            width={80}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Table
        title="Members"
        searchInputRef={searchInputRef}
        handleSearch={handleSearch}
        members
        maxAdmins={maxAdmins}
        totalAdmins={data.totalAdmins}
        openRoleModal={changeRole}
        paginationData={
          data.pages
            ? {
                currentPage: currentPage,
                totalPages: data.pages,
                totalItems: data.totalMembers,
                resultPerPage: data.resultPerPage,
                onPageChange,
              }
            : null
        }
        data={
          data.members
            ? data.members.map((mem) => {
                return {
                  name: mem.firstName,
                  email: mem.email,
                  createdAt: new Date(mem.createdAt).toLocaleDateString(
                    "en-GB"
                  ),
                  role: mem.type,
                  permissions: mem.permissions,
                  usages: mem.usage,
                  limit: mem.limit,
                };
              })
            : []
        }
        dataKeys={[
          "name",
          "email",
          "createdAt",
          "role",
          "permissions",
          "usages",
          "limit",
        ]}
        tableHeads={[
          "#",
          "Name",
          "email",
          "Created At",
          "Role",
          "Permissions",
          "Credits Spent",
          "Limit",
        ]}
      />
    </div>
  );
}
