import RemainingCreditsTable from "../RemainingCreditsTable/RemainingCreditsTable";
import Input from "../../../../components/Input/Input";
import Table from "../../../../components/Table/Table";
import MembersList from "../MembersList/MembersList";
import InvitationsList from "../InvitationsList/InvitationsList";

export default function DetailsSection({
  children,
  data,
  remainingCreditsTable,
  maxAdmins,
  user,
  expired,
}) {
  if (user && data.user) {
    return (
      <div>
        <h1 className={"title"}>{children}</h1>
        <div className={"container"}>
          <div className={"flexContainer"}>
            {data.user.isDeleted ? (
              <Input
                label={"Account Deleted"}
                value={data.user.isDeleted ?? "--"}
                customStyles={{ color: "red" }}
                disabled
              />
            ) : null}
            <Input
              label={"First Name"}
              value={data.user.firstName ?? "--"}
              disabled
            />
            <Input
              label={"Last Name"}
              value={data.user.lastName ?? "--"}
              disabled
            />
            <Input
              label={"Created At"}
              value={
                data.user.createdAt
                  ? new Date(data.user.createdAt).toLocaleDateString("en-GB")
                  : "--"
              }
              disabled
            />
            <Input
              label={"Organization"}
              value={data.organization ?? "--"}
              disabled
            />
            <Input
              label={"Role"}
              value={data.organizationRole ?? "--"}
              disabled
            />
            <Input label={"Email"} value={data.user.email ?? "--"} disabled />
            <Input
              label={"Phone"}
              value={
                data.user.phone
                  ? data.user.phone.countryCode + data.user.phone.phoneNumber
                  : "--"
              }
              disabled
            />
            <Input
              label={"Blocked"}
              value={data.user.isBlocked ?? "--"}
              disabled
            />
            <Input
              label={"Company"}
              value={
                data.user.company && data.user.company.length
                  ? data.user.company
                  : "--"
              }
              disabled
            />
            <Input
              label={"Title"}
              value={
                data.user.title && data.user.title.length
                  ? data.user.title
                  : "--"
              }
              disabled
            />
          </div>
          {remainingCreditsTable && !data.user.isDeleted && (
            <RemainingCreditsTable
              key="user"
              data={remainingCreditsTable}
              user={user}
              email={user ? data.email : data.customer.email}
            />
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <h1 className={"title"}>{children}</h1>
      <div className={"container"}>
        <div className={"flexContainer"}>
          <Input label={"Name"} value={data.name ?? "--"} disabled />
          <Input
            label={"Created At"}
            value={
              data.createdAt
                ? new Date(data.createdAt).toLocaleDateString("en-GB")
                : "--"
            }
            disabled
          />
          <Input label={"Public"} value={data.isPublic ?? "--"} disabled />
          <Input label={"Domains"} value={data.domains ?? "--"} disabled />
          <Input label={"Email"} value={data.email ?? "--"} disabled />
          <Input
            label={"Total Members"}
            value={data.totalMembers ?? "--"}
            disabled
          />
          <Input label={"Suspended"} value={data.suspended ?? "--"} disabled />
          {data.customer && (
            <Input
              label={"Customer ID"}
              value={data.customer.customerId ?? "--"}
              disabled
            />
          )}
          <Input
            label={"Company Size"}
            value={data.companySize ?? "--"}
            disabled
          />
        </div>
        {remainingCreditsTable && (
          <div className={expired ? "expired" : ""}>
            <RemainingCreditsTable
              key="organization"
              data={remainingCreditsTable}
              user={user}
              email={data.email}
            />
          </div>
        )}
        <br />
        <br />
        <br />
        <Table
          title="Workflows"
          data={data.workflows.map((wf) => {
            return {
              id: wf._id,
              active: wf.active ? "Yes" : "No",
              name: wf.name,
              creator: wf.creator,
              type: wf.type,
              totalContacts: wf.totalContacts,
              activeContacts: wf.activeContacts,
              createdAt: new Date(wf.createdAt).toLocaleDateString("en-GB"),
            };
          })}
          dataKeys={[
            "id",
            "active",
            "name",
            "creator",
            "type",
            "totalContacts",
            "activeContacts",
            "createdAt",
          ]}
          tableHeads={[
            "#",
            "ID",
            "Active",
            "Name",
            "Creator",
            "Type",
            "Total Contacts",
            "Active Contacts",
            "Created At",
          ]}
        />
        <br />
        <br />
        <br />
        <InvitationsList data={data} />
        <br />
        <br />
        <br />
        <MembersList maxAdmins={maxAdmins} />
      </div>
    </div>
  );
}
