import { useRef } from "react";
import Table from "../../../../components/Table/Table";

export default function InvitationsList({ data }) {
  const invitationsRef = useRef();

  return (
    <div ref={invitationsRef}>
      <Table
          title="Invitations"
          invitations
        data={data.invitations.map((inv) => {
            return {
              invitee: inv.invitee,
              createdAt: new Date(inv.createdAt).toLocaleDateString("en-GB"),
              role: inv.memberSettings.type,
              permissions: inv.memberSettings.permissions,
              inviterEmail: inv.inviter.email,
              limit: inv.memberSettings.limit,
            };
          })}
          dataKeys={[
            "invitee",
            "createdAt",
            "role",
            "permissions",
            "inviterEmail",
            "limit",
          ]}
          tableHeads={[
            "#",
            "Invitee Email",
            "Created At",
            "Invitee Organization Role",
            "Invitee Permissions",
            "Inviter Email",
            "Limit",
          ]}
        />
    </div>
  );
}
