import { createSlice } from "@reduxjs/toolkit";

export const memberToRestoreSlice = createSlice({
  name: "memberToRestore",
  initialState: {},
  reducers: {
    memberToRestore: (state, action) => {
      state.email = action.payload.email;
    },
  },
});

export const { memberToRestore } = memberToRestoreSlice.actions;

export default memberToRestoreSlice.reducer;
