import { useState, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { notifyLoading, notifyUpdate } from "../../utilities/utils";
import { createRewardCode } from "../../services/api/rewardCode";

export default function useRewardCode() {
  let toastId;
  const codeRef = useRef(null);
  const [rewardCode, setRewardCode] = useState();
  const queryClient = useQueryClient();
  const mutation = useMutation(createRewardCode, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("rewardCode");
      setRewardCode(codeRef.current.value);
      res
        ? notifyUpdate(toastId, "Create Successfully", "success")
        : notifyUpdate(toastId, "Failed to Create", "error");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await mutation.mutateAsync({
      code: codeRef.current.value,
      name: e.currentTarget.name.value,
      eventType: e.currentTarget.plan.value,
      workEmailCredits: 0,
      personalEmailCredits: e.currentTarget.DirectEmail.value,
      phoneCredits: e.currentTarget.Phone.value,
      exportCredits: e.currentTarget.Export.value,
      recurrencePeriodInSeconds:
        e.currentTarget.recurrence.value * 24 * 60 * 60,
      priority: 10,
      active: e.currentTarget.isActive.checked,
      legacyCredits: 0,
    });
  };

  return {
    states: { rewardCode, codeRef },
    setters: {},
    handlers: { handleSubmit },
  };
}
