import { toast } from "react-toastify";
import { ALL_ACTIONS } from "../constants";

const toastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const notifySuccess = (msg) =>
  toast.success(msg, {
    ...toastOptions,
    progressStyle: {
      backgroundColor: "darkcyan",
    },
    icon: () => <img src="/assets/images/successIcon.svg" />,
    style: { color: "darkcyan", fontSize: "1.2rem" },
  });

export const notifyLoading = (msg) => {
  const toastId = toast.loading(msg, toastOptions);
  return toastId;
};

export const notifyUpdate = (id, msg, type) => {
  if (type === "success") {
    toast.update(id, {
      render: msg,
      type: type,
      isLoading: false,
      ...toastOptions,
      progressStyle: {
        backgroundColor: "darkcyan",
      },
      icon: () => <img src="/assets/images/successIcon.svg" />,
      style: { color: "darkcyan", fontSize: "1.2rem" },
    });
  } else {
    toast.update(id, {
      render: msg,
      type: type,
      isLoading: false,
      ...toastOptions,
      icon: <img src="/assets/images/errorIcon.svg" />,
      progressStyle: {
        backgroundColor: "darkred",
      },
      style: { color: "darkred", fontSize: "1.2rem" },
    });
  }
};

export const notifyError = (msg) =>
  toast.error(msg, {
    ...toastOptions,
    icon: <img src="/assets/images/errorIcon.svg" />,
    progressStyle: {
      backgroundColor: "darkred",
    },
    style: { color: "darkred", fontSize: "1.2rem" },
  });

export const notifyInfo = (msg) =>
  toast.info(msg, { ...toastOptions, style: { fontSize: "1.2rem" } });

export function dataAdjustment(data) {
  let modifiedData = { ...data };
  let permissionsWithoutActions = [];
  let permissionsWithActions = [];

  if (!modifiedData.organizationCredits) {
    throw new Error("organizationCredits is null");
  }

  modifiedData.organizationCredits.plan.permissions
    .map((permission) => {
      if (permission.actions.length) {
        permissionsWithActions.push({
          ...permission,
          actions: permission.actions.map((action) => {
            return {
              checked: true,
              action: action,
              reference: action + permission.name,
            };
          }),
        });
      } else if (
        (permission.name !== "PublicApi") &
        (permission.name !== "HideGenericsEmails")
      ) {
        permissionsWithActions.push({ ...permission });
      } else {
        permissionsWithoutActions.push({ ...permission, checked: true });
      }
      return null;
    })
    .filter((permission) => permission !== null);

  for (const permission of ALL_ACTIONS) {
    const existingPermission = permissionsWithActions.find(
      (p) => p.name === permission.name
    );

    if (!existingPermission) {
      permissionsWithActions.push({
        ...permission,
        actions: permission.actions.map((action) => {
          return {
            checked: false,
            action: action,
            reference: action + permission.name,
          };
        }),
      });
    }
  }

  if (permissionsWithoutActions.length === 1) {
    permissionsWithoutActions[0].name === "PublicApi"
      ? permissionsWithoutActions.push({
          name: "HideGenericsEmails",
          actions: [],
          checked: false,
        })
      : permissionsWithoutActions.push({
          name: "PublicApi",
          actions: [],
          checked: false,
        });
  }
  if (!permissionsWithoutActions.length) {
    permissionsWithoutActions.push({
      name: "HideGenericsEmails",
      actions: [],
      checked: false,
    });
    permissionsWithoutActions.push({
      name: "PublicApi",
      actions: [],
      checked: false,
    });
  }

  return {
    org: modifiedData.organizationCredits.plan,
    subscriptionId:
      modifiedData.organizationCredits.subscription?.subscriptionId ?? null,
    permissionsWithActions,
    permissionsWithoutActions,
  };
}

export function convertIdOrEmail(data) {
  if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(data)) {
    return { email: data };
  }
  return { organizationId: data };
}

export function getEmojiRole(role) {
  switch (role) {
    case "owner":
      return "👑";
    case "admin":
      return "🛡️";
    case "member":
      return "👤";
  }
}

export function getCountry(currencyCode) {
  switch (currencyCode) {
    case "EUR":
      return ["FR"];
    case "USD":
      return ["US"];
    case "GBP":
      return ["GB"];
    default:
      return [];
  }
}

export function getCredit(type, amount) {
  switch (type) {
    case "exportCredits":
      return {
        exportCredits: amount,
        legacyCredits: 0,
        personalEmailCredits: 0,
        phoneCredits: 0,
        workEmailCredits: 0,
      };
    case "legacyCredits":
      return {
        legacyCredits: amount,
        exportCredits: 0,
        personalEmailCredits: 0,
        phoneCredits: 0,
        workEmailCredits: 0,
      };
    case "personalEmailCredits":
      return {
        personalEmailCredits: amount,
        legacyCredits: 0,
        exportCredits: 0,
        phoneCredits: 0,
        workEmailCredits: 0,
      };
    case "phoneCredits":
      return {
        phoneCredits: amount,
        legacyCredits: 0,
        personalEmailCredits: 0,
        exportCredits: 0,
        workEmailCredits: 0,
      };
    case "workEmailCredits":
      return {
        workEmailCredits: amount,
        legacyCredits: 0,
        personalEmailCredits: 0,
        phoneCredits: 0,
        exportCredits: 0,
      };
    default:
      return {
        exportCredits: 0,
        legacyCredits: 0,
        personalEmailCredits: 0,
        phoneCredits: 0,
        workEmailCredits: 0,
      };
  }
}

export function filterKeysAndValues(map, keysArray) {
  return Array.from(map).filter(
    ([key, value]) => keysArray.includes(key) && value === ""
  );
}

export function formatDateForHTML(date) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return `${year}-${month}-${day}`;
}

export function convertDateISO(inputDate) {
  const parsedDate = new Date(inputDate); // Parse the input date

  // Get the current time
  const currentTime = new Date();
  let hours = currentTime.getUTCHours().toString().padStart(2, "0");
  const minutes = currentTime.getUTCMinutes().toString().padStart(2, "0");
  const seconds = currentTime.getUTCSeconds().toString().padStart(2, "0");

  // Convert to ISO 8601 format (UTC) with the current time
  const isoDate = parsedDate.toISOString(); // Result: "2024-02-14T00:00:00.000Z"
  const adjustedIsoDate = isoDate.replace(
    "T00:00:00.000Z",
    `T${hours}:${minutes}:${seconds}.000Z`
  );
  return adjustedIsoDate;
}

export function mapCreditTypes(creditTypes) {
  const outputMapping = {
    "B2B Email": "workEmailCredits",
    "Direct Email": "personalEmailCredits",
    Phone: "phoneCredits",
    "Export Lead": "exportCredits",
    legacyCredits: "legacyCredits",
  };

  const output = creditTypes
    .filter((item) => item.checked)
    .map((item) => outputMapping[item.name]);
  return output;
}

export function mapSources(sources) {
  const outputMapping = {
    API: "publicApi",
    Dashboard: "dashboard",
    "Support team": "creditsWithdrawal",
    Subscription: "upgrade",
    "Free plan": "recurrentCredits",
    Extension: "plugin",
    "Sales automation": "workflow",
    Renew: "renew",
    "Onboarding credits": "freeCredits",
    "Bulk Enrichment": "bulkEnrichment",
  };
  const output = sources
    .filter((item) => item.checked)
    .map((item) => outputMapping[item.name]);
  return output;
}

export function downloadFile(data, filename) {
  let blob = new Blob([data], { type: "text/csv" });
  let URL = window.URL.createObjectURL(blob);
  let tempLink = document.createElement("a");
  tempLink.href = URL;
  tempLink.setAttribute("download", filename);
  tempLink.click();
}

export function getDomainFromEmail(email) {
  const parts = email.split("@");
  return parts[parts.length - 1];
}
