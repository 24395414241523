import { useState } from "react";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import { useQuery, useMutation } from "react-query";
import { updateCoupon } from "../../../services/api/coupons";
import { getCoupons } from "../../../services/api/coupons";
import { notifyLoading, notifyUpdate } from "../../../utilities/utils";
import Loader from "../../../components/Loader";
import ErrorPortal from "../../../components/ErrorPortal/ErrorPortal";

export default function Lists() {
  const { status, data, error } = useQuery("coupons", getCoupons);

  if (
    error ||
    (data && data.statusCode === 400) ||
    (status === "success" && !data)
  ) {
    return (
      <ErrorPortal
        title="Create Coupons"
        message="Some error occurred while fetching the data"
      />
    );
  }

  if (status === "loading")
    return (
      <div className="column gridFlow">
        <h1>List of Coupons</h1>
        <div>
          <Loader />
        </div>
      </div>
    );

  return (
    <div className="column gridFlow">
      <h1>List of Coupons</h1>
      <div className="gridFlow">
        <div className="items gridFlow update">
          {data
            .sort((a, b) => {
              const codeA = a.code.toUpperCase();
              const codeB = b.code.toUpperCase();

              if (codeA < codeB) {
                return -1;
              }
              if (codeA > codeB) {
                return 1;
              }
              return 0;
            })
            .map((coupon) => (
              <div key={coupon._id}>
                <Coupon
                  // name={coupon.code}
                  // isActive={coupon.active}
                  // id={coupon._id}
                  coupon={coupon}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function Coupon({ coupon }) {
  let toastId;
  const [checked, setChecked] = useState(coupon.active);
  const { mutate } = useMutation(updateCoupon, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update", "error");
    },
  });

  const handleClick = () => {
    mutate({
      code: coupon.code,
      update: {
        active: !checked,
      },
    });
    setChecked(!checked);
  };

  return (
    <>
      <label htmlFor={coupon.code}>{coupon.code}</label>
      <SwitchButton
        type="checkbox"
        id={coupon.code}
        toggled={checked}
        onClick={handleClick}
      />
    </>
  );
}
