import { API_URL } from "../../constants";

export const getCreditsUsage = async (payload) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/credits/activity/usages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};
