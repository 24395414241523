import "./CopyField.css";

export default function CopyField({ value }) {
  function handleCopy(event) {
    event.preventDefault();
    let inputElement = event.target.previousSibling;
    inputElement.select();
    navigator.clipboard.writeText(inputElement.value);
  }

  return (
    <div id="CopyField">
      <input type="text" value={value} readOnly className="inputField" />
      <button onClick={handleCopy} className="copyButton" />
    </div>
  );
}
