import { useParams, redirect } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getUserDetails,
  blockUser,
  unblockUser,
  deleteUser,
  restoreUser,
} from "../../../../services/api/api";
import {
  getDomainFromEmail,
  notifyLoading,
  notifyUpdate,
} from "../../../../utilities/utils";
import { useState } from "react";

export default function useUser() {
  let toastId;
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [showUnblockUserModal, setShowUnblockUserModal] = useState(false);
  const [ipToBlock, setIpToBlock] = useState(true);
  const [domainToBlock, setDomainToBlock] = useState(true);

  const params = useParams();
  const queryClient = useQueryClient();
  const { data, status, error } = useQuery(["userDetails", params], async () =>
    getUserDetails(params)
  );

  const deleteMutation = useMutation(deleteUser, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("userDetails");
      redirect("/v2");
      res
        ? notifyUpdate(toastId, "Deleted Successfully", "success")
        : notifyUpdate(toastId, "Failed to Delete", "error");
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Delete", "error");
    },
  });

  const blockUserMutation = useMutation(blockUser, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("userDetails");
      res
        ? notifyUpdate(toastId, "Blocked Successfully", "success")
        : notifyUpdate(toastId, "Failed to Block", "error");
    },
  });

  const unblockUserMutation = useMutation(unblockUser, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("userDetails");
      res
        ? notifyUpdate(toastId, "Unblocked Successfully", "success")
        : notifyUpdate(toastId, "Failed to Unblock", "error");
    },
  });

  const restoreUserMutation = useMutation(restoreUser, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("userDetails");
      res
        ? notifyUpdate(toastId, "Restored Successfully", "success")
        : notifyUpdate(toastId, "Failed to Restore", "error");
    },
  });

  const handleDelete = async () => {
    await deleteMutation.mutateAsync({
      ...params,
    });
  };

  const handleRestoreUser = async () => {
    await restoreUserMutation.mutateAsync({ ...params });
  };

  const handleBlock = async () => {
    await blockUserMutation.mutateAsync({
      ...params,
      inputIp: ipToBlock ? data.user.ip.ip : "",
      domain: domainToBlock ? getDomainFromEmail(data.user.email) : "",
      blockDomain: Boolean(domainToBlock),
    });
  };

  const handleUnblock = async () => {
    await unblockUserMutation.mutateAsync({
      ...params,
      inputIp: data.user.ip.ip,
      domain: domainToBlock ? getDomainFromEmail(data.user.email) : "",
      unblockDomain: Boolean(domainToBlock),
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.id === "ip") {
      setIpToBlock(!ipToBlock);
    }
    if (event.target.id === "domain") {
      setDomainToBlock(!domainToBlock);
    }
  };

  return {
    states: {
      data,
      status,
      error,
      showDeleteUserModal,
      showBlockUserModal,
      showUnblockUserModal,
      ipToBlock,
      domainToBlock,
      params,
    },
    setters: {
      setShowDeleteUserModal,
      setShowBlockUserModal,
      setShowUnblockUserModal,
    },
    handlers: {
      handleUnblock,
      handleBlock,
      handleRestoreUser,
      handleDelete,
      handleCheckboxChange,
    },
  };
}
