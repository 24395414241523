import { useState } from "react";
import "./SwitchButton.css";

export default function SwitchButton({
  type,
  id,
  option,
  label,
  toggled,
  onClick,
}) {
  const [toggle, setToggle] = useState(toggled);

  const callback = () => {
    setToggle(!toggle);
    onClick(!toggle);
  };

  return (
    <div id="SwitchButton">
      <label>
        <input
          type={type}
          id={id}
          name={option}
          defaultChecked={toggle}
          onClick={callback}
        />
        <span />
        <strong>{label}</strong>
      </label>
    </div>
  );
}
