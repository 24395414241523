export default function Loader() {
  return (
    <div className="ldsEllipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export function FullPageLoader() {
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <Loader />
    </div>
  );
}
