import { CheckboxSet } from "./CheckboxSet";
import { notifyInfo } from "../utilities/utils";

function Field({
  title,
  value,
  type = "text",
  step,
  onChange,
  placeholder,
  disabled,
  id,
  creditTypes,
  bulkTypes,
  toolTip,
  min,
}) {
  return (
    <div className={"form-group"} data-tip={toolTip || null}>
      <label htmlFor={id}>{title}</label>
      <input
        id={id}
        type={type}
        step={step}
        value={value}
        placeholder={placeholder || title + "..."}
        disabled={disabled}
        onChange={(e) =>
          onChange(
            type !== "number" ? e.target.value : Number(e.target.value),
            id,
            creditTypes,
            bulkTypes
          )
        }
        min={min || 0}
      />
    </div>
  );
}

function Permissions({
  permissionsWithActions,
  setPermissionsWithActions,
  reset,
  addMember,
}) {
  if (addMember) {
    return permissionsWithActions.map((permission) => {
      return (
        <CheckboxSet
          key={permission._id || permission.name}
          permission={permission}
          permissionsWithActions={permissionsWithActions}
          setPermissionsWithActions={setPermissionsWithActions}
          reset={reset}
          addMember
        />
      );
    });
  }

  return permissionsWithActions.map((permission) => {
    return (
      <CheckboxSet
        key={permission._id || permission.name}
        permission={permission}
        permissionsWithActions={permissionsWithActions}
        setPermissionsWithActions={setPermissionsWithActions}
        reset={reset}
      />
    );
  });
}

function FormActions({ onReset, onSubmit, create, addMember }) {
  if (create)
    return (
      <div className={"btn-groups"}>
        <button
          className="createBtn"
          type={"submit"}
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          Create
        </button>
      </div>
    );

  if (addMember)
    return (
      <div className={"btn-groups"}>
        <button
          className="createBtn"
          type={"submit"}
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          Add Member
        </button>
      </div>
    );

  return (
    <div className={"btn-groups"}>
      <button
        className="resetBtn"
        onClick={(e) => {
          onReset(e);
          notifyInfo("Reset Successfully");
        }}
      >
        Reset
      </button>
      <button
        type={"submit"}
        onClick={(e) => {
          onSubmit(e);
        }}
      >
        Update
      </button>
    </div>
  );
}

export { Field, FormActions, Permissions };
