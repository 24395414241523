import "./CreditsUsage.css";

import moment from "moment";
import Loader from "../../components/Loader";
import Table from "../../components/Table/Table";
import ErrorPortal from "../../components/ErrorPortal/ErrorPortal";
import HomeButton from "../../components/HomeButton";
import { getCredit } from "../../utilities/utils";
import useCreditsUsage from "./CreditsUsage.hook";
import Filters from "./common/Filters/Filters";

export default function CreditsUsage({ pageTitle }) {
  const { states, setters, handlers } = useCreditsUsage(pageTitle);

  if (
    states.error ||
    (states.data && states.data.statusCode === 400) ||
    (states.status === "success" && !states.data.creditUsage)
  ) {
    return <ErrorPortal title="Credits Usage" />;
  }

  if (states.status === "loading") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <HomeButton />
      <div id="CreditsUsage">
        <h1>{pageTitle}</h1>
        <div className="container" ref={states.tableRef}>
          <Filters
            filter={states.filter}
            setFilter={setters.setFilter}
            organizationId={states.organizationId}
          />
          <Table
            title={`Total Spent Credits: ${states.data.usages}`}
            invitations
            creditsUsageTable
            paginationData={{
              currentPage: states.currentPage,
              totalPages: states.data.pages.totalPages,
              totalItems: states.data.usages,
              resultPerPage: states.data.pages.resultPerPage,
              onPageChange: handlers.onPageChange,
            }}
            data={states.data.creditUsage.map((cu) => {
              return {
                email: cu.email,
                target: cu.id,
                usageType: cu.source,
                // automationType: cu.infos.automationType,
                // searchUrl: cu.infos.searchUrl,
                createdAt: moment(cu.createdat).format("DD/MM/YYYY, HH:mm"),
                isDecreased: cu.isdecreased ? "Yes" : "No",
                limit: getCredit(cu.type, cu.amount),
                organizationId: cu.organizationid,
              };
            })}
            dataKeys={[
              "email",
              "target",
              "usageType",
              "createdAt",
              "limit",
              "organizationId",
            ]}
            tableHeads={[
              "#",
              "User",
              "Target",
              "Usage Type",
              "Created Time",
              "Amount",
              "Organization Id",
            ]}
          />
        </div>
      </div>
    </>
  );
}
