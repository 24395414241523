import { useState } from "react";
import { useRef } from "react";
import { CustomCheckbox } from "../../../../components/Input/Input";
import DropdownBoard from "../../../../components/DropdownBoard/DropdownBoard";
import Button from "../../../../components/Button/Button";
import {
  convertDateISO,
  downloadFile,
  formatDateForHTML,
  notifyError,
  notifySuccess,
} from "../../../../utilities/utils";
import { exportCreditsUsage } from "../../../../services/api/api";

export function SourcesCheckboxes({ sources, setSources }) {
  const sourceRef = useRef(null);
  const handleSources = (index) => {
    const updatedSources = [...sources];
    updatedSources[index].checked = !updatedSources[index].checked;
    setSources(updatedSources);
  };

  return (
    <>
      {sources.map((source, index) => (
        <CustomCheckbox
          key={`${(source.name, index)}`}
          label={source.name}
          checked={source.checked}
          ref={sourceRef}
          onChange={() => handleSources(index)}
        />
      ))}
    </>
  );
}

export function CreditsCheckboxes({ credits, setCredits }) {
  const creditRef = useRef(null);
  const handleCredits = (index) => {
    const updatedCredits = [...credits];
    updatedCredits[index].checked = !updatedCredits[index].checked;
    setCredits(updatedCredits);
  };

  return (
    <>
      {credits.map((credit, index) => (
        <CustomCheckbox
          key={`${(credit.name, index)}`}
          label={credit.name}
          checked={credit.checked}
          ref={creditRef}
          onChange={() => handleCredits(index)}
        />
      ))}
    </>
  );
}

export function SourcesMenu({ sources, setSources, onOutsideClick }) {
  return (
    <DropdownBoard key="sourcesMenu" onOutsideClick={onOutsideClick}>
      <SourcesCheckboxes sources={sources} setSources={setSources} />
    </DropdownBoard>
  );
}

export function CreditsMenu({ credits, setCredits, onOutsideClick }) {
  return (
    <DropdownBoard key="creditsMenu" onOutsideClick={onOutsideClick}>
      <CreditsCheckboxes credits={credits} setCredits={setCredits} />
    </DropdownBoard>
  );
}

export function ExportMenu({ handleClick, onOutsideClick }) {
  return (
    <DropdownBoard key="ExportMenu" exportMenu onOutsideClick={onOutsideClick}>
      <div className="item" onClick={() => handleClick("Report")}>
        Report
      </div>
      <div className="item" onClick={() => handleClick("Statistics")}>
        Statistics
      </div>
    </DropdownBoard>
  );
}

export default function Filters({ filter, setFilter, organizationId }) {
  const [exportOnce, setExportOnce] = useState(false);
  const [openSourcesMenu, setOpenSourcesMenu] = useState(false);
  const [openCreditsMenu, setOpenCreditsMenu] = useState(false);
  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [sources, setSources] = useState(
    filter
      ? filter.sources
      : [
          { name: "API", checked: true },
          { name: "Dashboard", checked: true },
          { name: "Support team", checked: true },
          { name: "Subscription", checked: true },
          { name: "Free plan", checked: true },
          { name: "Extension", checked: true },
          { name: "Sales automation", checked: true },
          { name: "Renew", checked: true },
          { name: "Onboarding credits", checked: true },
          { name: "Bulk Enrichment", checked: true },
        ]
  );
  const [credits, setCredits] = useState(
    filter
      ? filter.credits
      : [
          { name: "B2B Email", checked: true },
          { name: "Direct Email", checked: true },
          { name: "Phone", checked: true },
          { name: "Export Lead", checked: true },
        ]
  );
  const [startDate, setStartDate] = useState(
    filter
      ? filter.startDate
      : () => {
          const today = new Date();
          today.setMonth(today.getMonth() - 1);
          return formatDateForHTML(today);
        }
  );
  const [endDate, setEndDate] = useState(
    filter
      ? filter.endDate
      : () => {
          const today = new Date();
          return formatDateForHTML(today);
        }
  );
  const [minDate] = useState(() => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 4);
    return formatDateForHTML(today);
  });

  const handleOpenSourcesMenu = () => setOpenSourcesMenu(!openSourcesMenu);
  const handleOpenCreditsMenu = () => setOpenCreditsMenu(!openCreditsMenu);
  const handleOpenExportMenu = () => setOpenExportMenu(!openExportMenu);
  const handleCloseSourcesMenu = () => setOpenSourcesMenu(false);
  const handleCloseCreditsMenu = () => setOpenCreditsMenu(false);
  const handleCloseExportMenu = () => setOpenExportMenu(false);
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleFetch = () => {
    setFilter({ startDate, endDate, sources, credits });
  };

  const handleExport = async (fileType) => {
    if (exportOnce) {
      return notifyError("Already Exported");
    }
    const exportPayload = {
      startDate: convertDateISO(startDate),
      endDate: convertDateISO(endDate),
      fileType,
      organizationId,
    };
    setExportOnce(true);
    notifySuccess("Exported Successfully");
    const blobData = await exportCreditsUsage(exportPayload);
    downloadFile(blobData, `creditsUsage-${fileType}.xlsx`);
  };

  return (
    <div>
      <label htmlFor="start">Start date:</label>
      <input
        type="date"
        id="start"
        name="startDate"
        value={startDate}
        min={minDate}
        max={endDate}
        onChange={handleStartDateChange}
      />
      <label htmlFor="end">End date:</label>
      <input
        type="date"
        id="end"
        name="endDate"
        value={endDate}
        min={minDate}
        max={new Date().toLocaleDateString("en-GB")}
        onChange={handleEndDateChange}
      />
      <div className="filtersContainer">
        <Button handleClick={handleOpenSourcesMenu}>
          <div style={{ display: "inline-flex" }}>
            Sources
            <img className="chevron" src="/assets/images/chevron-down.svg" />
          </div>
        </Button>
        {openSourcesMenu && (
          <SourcesMenu
            sources={sources}
            setSources={setSources}
            onOutsideClick={handleCloseSourcesMenu}
          />
        )}
        <Button handleClick={handleOpenCreditsMenu}>
          <div style={{ display: "inline-flex" }}>
            Credits
            <img className="chevron" src="/assets/images/chevron-down.svg" />
          </div>
        </Button>
        {openCreditsMenu && (
          <CreditsMenu
            credits={credits}
            setCredits={setCredits}
            onOutsideClick={handleCloseCreditsMenu}
          />
        )}
        <Button handleClick={handleFetch}>
          <div style={{ display: "inline-flex" }}>
            Fetch
            <img
              className="chevron"
              src="/assets/images/magnifying-glass.svg"
            />
          </div>
        </Button>
        <Button handleClick={handleOpenExportMenu}>
          <div style={{ display: "inline-flex" }}>
            Export
            <img className="chevron" src="/assets/images/download.svg" />
          </div>
        </Button>
        {openExportMenu && (
          <ExportMenu
            handleClick={handleExport}
            onOutsideClick={handleCloseExportMenu}
          />
        )}
      </div>
    </div>
  );
}
