import "./RewardCode.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import CopyField from "./common/CopyField";
import Update from "./common/Update";
import HomeButton from "../../components/HomeButton";
import useRewardCode from "./RewardCode.hook";

export default function RewardCode() {
  const { states, setters, handlers } = useRewardCode();

  return (
    <>
      <HomeButton />
      <div id="RewardCode">
        <form
          className="column gridFlow"
          onSubmit={(e) => handlers.handleSubmit(e)}
        >
          <div>
            <h1>Create Reward Code</h1>
          </div>
          <div className="items gridFlow">
            <div>
              <label htmlFor="code">Code:</label>
              <input
                id="code"
                ref={states.codeRef}
                className="inputField"
                placeholder="e.g. Balckfriday"
              />
            </div>
            <div>
              <label htmlFor="name">Name:</label>
              <input id="name" className="inputField" placeholder="name" />
            </div>
            <div>
              <label>Credits Amount:</label>
              <div className="creditContainer">
                <Input label={"Phone"} />
                <Input label={"Direct Email"} />
                <Input label={"Export"} />
              </div>
            </div>
            <div>
              <label htmlFor="recurrence">Recurrence Period in Days:</label>
              <input
                id="recurrence"
                className="inputField"
                placeholder="Number of days"
              />
            </div>
            <div>
              <label htmlFor="plan">Plan:</label>
              <select name="plan" id="plan" defaultValue="-- Select credits --">
                <option value="addPersonalRecurrentCredits">
                  personal credits
                </option>
                <option value="addOrganizationRecurrentCredits">
                  organization credits
                </option>
              </select>
            </div>
            <div>
              <div className="isActive">
                <input type="checkbox" id="isActive" />
                <label htmlFor="isActive">Is Active</label>
              </div>
            </div>
          </div>
          {states.rewardCode && (
            <CopyField
              value={`https://app.kaspr.io/signup?code=${states.rewardCode}`}
            />
          )}
          <div>
            <Button type="submit" width="200px">
              Create
            </Button>
          </div>
        </form>
        <Update />
      </div>
    </>
  );
}
