import "./CreateOrganizationPlan.css";
import {
  SectionWrapper,
  MainWrapper,
  GroupWrapper,
} from "../../components/Wrappers";
import { Field, FormActions, Permissions } from "../../components/Tools";
import useOrganizationPlan from "./CreateOrganizationPlan.hooks";
import ReactTooltip from "react-tooltip";
import HomeButton from "../../components/HomeButton";
import { FullPageLoader } from "../../components/Loader";
import ErrorPortal from "../../components/ErrorPortal/ErrorPortal";

export default function CreateOrganizationPlan() {
  const { states, setters, handlers } = useOrganizationPlan();

  const toolTip = " -1 for unlimited";

  if (states.status === "loading") {
    return <FullPageLoader />;
  }

  if (states.error) {
    return <ErrorPortal title="Add Member" message={states.error.message} />;
  }

  return (
    <>
      <HomeButton />
      <div id="createPlan">
        <MainWrapper
          onSubmit={handlers.onSubmit}
          title={"Create Custom Plan"}
          styleId="createPlan"
        >
          <SectionWrapper title={"Plan settings"}>
            <GroupWrapper>
              <Field
                title={"Plan id"}
                id={"planId"}
                value={states.state.planId}
                onChange={handlers.onChange}
              />
              <Field
                title={"Name"}
                id={"name"}
                value={states.state.name}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                type={"number"}
                title={"Number of workflows"}
                id={"numberOfWorkflow"}
                value={states.state.numberOfWorkflow}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Number of sequences"}
                id={"numberOfSequences"}
                value={states.state.numberOfSequences}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Workflow launches"}
                id={"numberOfWorkflowLaunches"}
                value={states.state.numberOfWorkflowLaunches}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Contact per launch"}
                id={"contactPerLaunch"}
                value={states.state.contactPerLaunch}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                type={"number"}
                title={"Work Email Credits per seat"}
                id={"workEmailCreditsPerSeat"}
                value={states.state.workEmailCreditsPerSeat}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Personal Email Credits per seat"}
                id={"personalEmailCreditsPerSeat"}
                value={states.state.personalEmailCreditsPerSeat}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Phone Credits per seat"}
                id={"phoneCreditsPerSeat"}
                value={states.state.phoneCreditsPerSeat}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Export Credits per seat"}
                id={"exportCreditsPerSeat"}
                value={states.state.exportCreditsPerSeat}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                type={"number"}
                step="0.1"
                title={"Price per seat"}
                id={"pricePerSeat"}
                value={states.state.pricePerSeat}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Number of seats"}
                id={"numberOfSeats"}
                value={states.state.numberOfSeats}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Number of months"}
                id={"numberOfMonths"}
                value={states.state.numberOfMonths}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                type={"number"}
                title={"Min seats"}
                id={"minSeats"}
                value={states.state.minSeats}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Max seats"}
                id={"maxSeats"}
                value={states.state.maxSeats}
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                type={"number"}
                title={"Max lists"}
                id={"maxLists"}
                value={states.state.maxLists}
                onChange={handlers.onChange}
                toolTip={toolTip}
                min={-1}
              />
              <Field
                type={"number"}
                title={"Max tags"}
                id={"maxTags"}
                value={states.state.maxTags}
                onChange={handlers.onChange}
                toolTip={toolTip}
                min={-1}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                type={"number"}
                title={"Max admins"}
                id={"maxAdmins"}
                value={states.state.maxAdmins}
                min={-1}
                onChange={handlers.onChange}
              />
              <div className="form-group">
                <label htmlFor="currency">Currency</label>
                <select
                  name="currency"
                  id="currency"
                  onChange={(e) =>
                    handlers.onChange(e.target.value, "currency")
                  }
                >
                  {states.currencies.map((currency, i) => {
                    return (
                      <option
                        key={`currency_${i}_${currency.currency}`}
                        value={currency.currency}
                      >
                        {currency.currency}
                      </option>
                    );
                  })}
                </select>
              </div>
            </GroupWrapper>
          </SectionWrapper>
          <SectionWrapper className={"form-line"}>
            <input
              type="checkbox"
              id={"isCumulative"}
              style={{ cursor: "pointer" }}
              onChange={(e) =>
                handlers.onChange(e.target.checked, "isCumulative")
              }
              checked={states.state.isCumulative}
            />
            <label htmlFor="isCumulative" style={{ cursor: "pointer" }}>
              Is cumulative
            </label>
          </SectionWrapper>
          <SectionWrapper
            title={"Plan permissions"}
            className={"permissions-wrapper"}
          >
            <Permissions
              permissionsWithActions={states.permissionsWithActions}
              setPermissionsWithActions={setters.setPermissionsWithActions}
            />
          </SectionWrapper>
          {states.permissionsWithoutActions.map((p) => {
            const mappingNames = [
              { codeName: "PublicApi", displayName: "Public Api" },
              {
                codeName: "HideGenericsEmails",
                displayName: "Hide Generic Emails",
              },
            ];
            return (
              <SectionWrapper className={"form-line"} key={p.name}>
                <input
                  type="checkbox"
                  id={p.name}
                  style={{ cursor: "pointer" }}
                  onChange={() => {
                    setters.setPermissionsWithoutActions(
                      states.permissionsWithoutActions.map((pe) => {
                        if (pe.name === p.name)
                          return { ...pe, checked: !pe.checked };
                        return { ...pe };
                      })
                    );
                  }}
                  checked={p.checked}
                />
                <label htmlFor={p.name} style={{ cursor: "pointer" }}>
                  {mappingNames.map((m) => {
                    if (m.codeName === p.name) {
                      return m.displayName;
                    }
                    return null;
                  })}
                </label>
              </SectionWrapper>
            );
          })}
          <FormActions
            onReset={handlers.onResetState}
            onSubmit={handlers.onSubmit}
            create
          />
          <ReactTooltip effect="solid" offset={{ bottom: 7 }} />
        </MainWrapper>
      </div>
    </>
  );
}
