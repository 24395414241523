import "./Details.css";
import User from "./common/User/User";
import Organization from "./common/Organization/Organization";

export default function Details({ user }) {
  if (user) {
    return <User />;
  }
  return <Organization />;
}
