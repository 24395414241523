import { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Permissions from "./Permissions";
import Input from "../../../../components/Input/Input";
import AttachPlanModal from "./AttachPlanModal";
import Button from "../../../../components/Button/Button";

export default function PlanSection({
  children,
  data,
  subscriptionData,
  email,
  attachToPlan,
  orgId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const permissionsToCheck = ["PublicApi", "HideGenericsEmails"];

  permissionsToCheck.forEach((permissionToCheck) => {
    const hasElement = data.permissions.some(
      (obj) => obj.name === permissionToCheck
    );

    if (hasElement) {
      data.permissions.forEach((obj) => {
        if (obj.name === permissionToCheck) {
          obj.exists = true;
        }
      });
    } else {
      data.permissions.push({ name: permissionToCheck, exists: false });
    }
  });

  return (
    <div>
      <h1 className="title">{children}</h1>
      <div className="container">
        <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
          <AttachPlanModal orgId={orgId} setIsOpen={setIsOpen} email={email} />
        </Modal>
        {attachToPlan && (
          <div className="btnContainer">
            <Button
              width="200px"
              type="button"
              handleClick={() => setIsOpen(true)}
            >
              Attach to Plan
            </Button>
          </div>
        )}
        <div
          className={`flexContainer ${
            subscriptionData
              ? new Date(subscriptionData.endDate) < new Date()
                ? "expired"
                : ""
              : ""
          }`}
        >
          <Input label={"Name"} value={data.name} disabled />
          <Input label={"Plan ID"} value={data.planId} disabled />
          <Input
            label={"Contact per Launch"}
            value={data.contactPerLaunch}
            disabled
          />
          <Input
            label={"Created At"}
            value={
              data.createdAt
                ? new Date(data.createdAt).toLocaleDateString("en-GB")
                : ""
            }
            disabled
          />
          <Input label={"Cumulative"} value={data.isCumulative} disabled />
          <Input label={"Max Admins"} value={data.maxAdmins} disabled />
          <Input label={"Max Lists"} value={data.maxLists} disabled />
          <Input label={"Max Seats"} value={data.maxSeats} disabled />
          <Input label={"Max Tags"} value={data.maxTags} disabled />
          <Input label={"Min Seats"} value={data.minSeats} disabled />
          <Input
            label={"Number of Months"}
            value={data.numberOfMonths}
            disabled
          />
          <Input
            label={"Number of Seats"}
            value={data.numberOfSeats}
            disabled
          />
          <Input
            label={"Number of Sequences"}
            value={data.numberOfSequences}
            disabled
          />
          <Input
            label={"Number of Workflow"}
            value={data.numberOfWorkflow}
            disabled
          />
          <Input
            label={"Workflow Launches"}
            value={data.numberOfWorkflowLaunches}
            disabled
          />
          <Input label={"Price per Seat"} value={data.pricePerSeat} disabled />
          <Input label={"Version"} value={data.version} disabled />
        </div>
        {subscriptionData && (
          <div
            className={`flexContainer ${
              new Date(subscriptionData.endDate) < new Date() ? "expired" : ""
            }`}
          >
            <Input
              label={"Subscription ID"}
              value={subscriptionData.subscriptionId}
              disabled
            />
            <Input
              label={"Start Date"}
              value={
                subscriptionData.startDate
                  ? new Date(subscriptionData.startDate).toLocaleDateString(
                      "en-GB"
                    )
                  : "--"
              }
              disabled
            />
            <Input
              label={"Last Renewal"}
              value={
                subscriptionData.lastRenewDate
                  ? new Date(subscriptionData.lastRenewDate).toLocaleDateString(
                      "en-GB"
                    )
                  : "--"
              }
              disabled
            />
            <Input
              label={"End Date"}
              value={
                subscriptionData.endDate
                  ? new Date(subscriptionData.endDate).toLocaleDateString(
                      "en-GB"
                    )
                  : "--"
              }
              disabled
            />
            <Input
              label={"Deadline"}
              value={
                subscriptionData.nonRenewalNoticeDeadline
                  ? new Date(
                      subscriptionData.nonRenewalNoticeDeadline
                    ).toLocaleDateString("en-GB")
                  : "--"
              }
              disabled
            />
          </div>
        )}
        <h2 className="title2">Permissions</h2>
        <hr />
        {data.permissions.map((p, i) => {
          return (
            <Permissions key={p.name} permissionSet={p} index={i} data={data} />
          );
        })}
      </div>
    </div>
  );
}
