import { useEffect, useRef, useState } from "react";
import "./RestoreOrganization.css";
import HomeButton from "../../components/HomeButton";
import SearchOrganization from "./common/SearchOrganization";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RestoreSuspendedOrg, getSuspendedOrg } from "../../services/api/api";
import Loader from "../../components/Loader";
import { notifyLoading, notifyUpdate } from "../../utilities/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RestoreOrganizations() {
  let toastId;
  const searchRef = useRef();
  const [searchInput, setSearchInput] = useState("");
  const [membersList, setMembersList] = useState([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { status, data } = useQuery(
    ["OrganizationToRestore", searchInput],
    () => {
      return searchInput
        ? getSuspendedOrg({ organizationId: searchInput })
        : null;
    }
  );

  const mutation = useMutation(RestoreSuspendedOrg, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Restored Successfully", "success")
        : notifyUpdate(toastId, "Failed to Restore", "error");
      setTimeout(() => {
        // queryClient.invalidateQueries("OrganizationToRestore");
        // navigate(`/RestoreOrganizations`);
        window.location.reload();
      }, 5500);
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Restore", "error");
    },
  });

  const emailToRestore = useSelector((state) => state.memberToRestore.email);

  const handleRestore = (e) => {
    mutation.mutate({
      email: emailToRestore ?? membersList[0].email,
      setOrganizationAsPaid:
        e.target.innerHTML === "Restore Paid" ? true : false,
    });
  };

  const handleSearch = () => {
    setSearchInput(searchRef.current?.value);
  };

  useEffect(() => {
    if (searchInput && status === "success") {
      setMembersList(data?.members);
    }
  }, [searchInput, status]);

  if (status === "loading") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div id="BO">
      <div className="header">
        <HomeButton />
        <h1 className="title">Restore Organizations</h1>
      </div>
      <div className="content">
        <div className="searchWrapper">
          <SearchOrganization
            searchInputRef={searchRef}
            handleSearch={handleSearch}
          />
        </div>
        <Table
          title="Workspace Name"
          restoreWorkspace
          data={membersList.map((member, i) => {
            return {
              name: `${member.firstName} ${member.lastName}`,
              email: member.email,
              role: member.type,
              selected: i === 0 ? true : false,
            };
          })}
          dataKeys={["name", "email", "role"]}
          tableHeads={["#", "Name", "Email", "Role"]}
        />
        <div className="restoreButtons">
          <Button handleClick={handleRestore}>Restore Unpaid</Button>
          <Button handleClick={handleRestore}>Restore Paid</Button>
        </div>
      </div>
    </div>
  );
}
