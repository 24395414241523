import { useState, useEffect } from "react";
import { ALL_ACTIONS, ALL_ACTIONS_ADD_MEMBER } from "../constants";

export function CheckboxSet({
  permission,
  permissionsWithActions,
  setPermissionsWithActions,
  reset,
  addMember,
}) {
  const [nameChecked, setNameChecked] = useState(true);

  useEffect(() => {
    let oneIsTrue = false;
    permissionsWithActions.map((singlePermission) => {
      if (singlePermission.name === permission.name) {
        singlePermission.actions.map((singleAction) => {
          if (singleAction.checked) oneIsTrue = true;
        });
      }
    });
    setNameChecked(oneIsTrue);
  }, [reset]);

  const handleClick = () => {
    setNameChecked(!nameChecked);
    let newActions = null;
    ALL_ACTIONS.map((singlePermission) => {
      if (singlePermission.name === permission.name) {
        newActions = singlePermission.actions.map((singleAction) => {
          if (nameChecked) {
            return { ...singleAction, checked: false };
          }
          return { ...singleAction, checked: true };
        });
        return newActions;
      }
      return null;
    }).filter((singlePermission) => singlePermission !== null);
    setPermissionsWithActions(
      permissionsWithActions.map((singlePermission) => {
        if (singlePermission.name === permission.name) {
          return {
            ...singlePermission,
            actions: [...newActions],
          };
        }
        return { ...singlePermission };
      })
    );
  };

  const handleClickAddMember = () => {
    setNameChecked(!nameChecked);
    let newActions = null;
    ALL_ACTIONS_ADD_MEMBER.map((singlePermission) => {
      if (singlePermission.name === permission.name) {
        newActions = singlePermission.actions.map((singleAction) => {
          if (nameChecked) {
            return { ...singleAction, checked: false };
          }
          return { ...singleAction, checked: true };
        });
        return newActions;
      }
      return null;
    }).filter((singlePermission) => singlePermission !== null);
    setPermissionsWithActions(
      permissionsWithActions.map((singlePermission) => {
        if (singlePermission.name === permission.name) {
          return {
            ...singlePermission,
            actions: [...newActions],
          };
        }
        return { ...singlePermission };
      })
    );
  };

  return (
    <fieldset>
      <legend style={{ cursor: "pointer" }}>
        <input
          type="checkbox"
          checked={nameChecked}
          onChange={addMember ? handleClickAddMember : handleClick}
        />
        <span onClick={addMember ? handleClickAddMember : handleClick}>
          {permission.name}
        </span>
      </legend>
      <div className={"permissions-fieldset"}>
        {addMember
          ? ALL_ACTIONS_ADD_MEMBER.map((singlePermission) => {
              if (singlePermission.name === permission.name) {
                return singlePermission.actions.map((singleAllActions) => {
                  let checkboxFields = [];
                  let notFound = false;
                  checkboxFields = permission.actions.map((singleAction) => {
                    if (singleAction.action === singleAllActions.action) {
                      return (
                        <CheckboxField
                          key={singleAllActions.reference}
                          id={singleAllActions.reference}
                          action={singleAllActions.action}
                          checked={singleAction.checked}
                          permissionsWithActions={permissionsWithActions}
                          setPermissionsWithActions={setPermissionsWithActions}
                          nameChecked={nameChecked}
                          setNameChecked={setNameChecked}
                          permissionName={permission.name}
                        />
                      );
                    }
                    return null;
                  });
                  checkboxFields = checkboxFields.filter(
                    (checkbox) => checkbox !== null
                  );
                  notFound = checkboxFields.length;
                  if (!notFound) {
                    checkboxFields.push(
                      <CheckboxField
                        key={singleAllActions.reference}
                        id={singleAllActions.reference}
                        action={singleAllActions.action}
                        checked={false}
                        permissionsWithActions={permissionsWithActions}
                        setPermissionsWithActions={setPermissionsWithActions}
                        nameChecked={nameChecked}
                        setNameChecked={setNameChecked}
                        permissionName={permission.name}
                      />
                    );
                  }
                  return checkboxFields;
                });
              }
              return null;
            })
          : ALL_ACTIONS.map((singlePermission) => {
              if (singlePermission.name === permission.name) {
                return singlePermission.actions.map((singleAllActions) => {
                  let checkboxFields = [];
                  let notFound = false;
                  checkboxFields = permission.actions.map((singleAction) => {
                    if (singleAction.action === singleAllActions.action) {
                      return (
                        <CheckboxField
                          key={singleAllActions.reference}
                          id={singleAllActions.reference}
                          action={singleAllActions.action}
                          checked={singleAction.checked}
                          permissionsWithActions={permissionsWithActions}
                          setPermissionsWithActions={setPermissionsWithActions}
                          nameChecked={nameChecked}
                          setNameChecked={setNameChecked}
                          permissionName={permission.name}
                        />
                      );
                    }
                    return null;
                  });
                  checkboxFields = checkboxFields.filter(
                    (checkbox) => checkbox !== null
                  );
                  notFound = checkboxFields.length;
                  if (!notFound) {
                    checkboxFields.push(
                      <CheckboxField
                        key={singleAllActions.reference}
                        id={singleAllActions.reference}
                        action={singleAllActions.action}
                        checked={false}
                        permissionsWithActions={permissionsWithActions}
                        setPermissionsWithActions={setPermissionsWithActions}
                        nameChecked={nameChecked}
                        setNameChecked={setNameChecked}
                        permissionName={permission.name}
                      />
                    );
                  }
                  return checkboxFields;
                });
              }
              return null;
            })}
      </div>
    </fieldset>
  );
}

export function CheckboxField({
  id,
  action,
  checked,
  permissionsWithActions,
  setPermissionsWithActions,
  nameChecked,
  setNameChecked,
  permissionName,
}) {
  const handleClick = () => {
    let oneIsTrue = false;
    setPermissionsWithActions(
      permissionsWithActions.map((singlePermission) => {
        if (singlePermission.name === permissionName) {
          let newActions = singlePermission.actions.map((singleAction) => {
            if (singleAction.action === action) {
              if ((oneIsTrue === false) & !singleAction.checked) {
                oneIsTrue = true;
              }
              return { ...singleAction, checked: !singleAction.checked };
            }
            if (singleAction.checked & (oneIsTrue === false)) {
              oneIsTrue = true;
            }
            return singleAction;
          });
          if (
            !newActions.some((singleAction) => singleAction.action === action)
          ) {
            newActions.push({ checked: true, action: action, reference: id });
          }
          if (!singlePermission.actions.length) {
            oneIsTrue = true;
          }
          return {
            ...singlePermission,
            actions: newActions,
          };
        }
        return { ...singlePermission };
      })
    );
    if ((nameChecked === false) & (oneIsTrue === true)) {
      setNameChecked(true);
    }
    if ((nameChecked === true) & (oneIsTrue === false)) {
      setNameChecked(false);
    }
  };

  return (
    <label htmlFor={id}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleClick}
        style={{ cursor: "pointer" }}
      />
      <span style={{ color: "#555", margin: 0, padding: 0, cursor: "pointer" }}>
        {action}
      </span>
    </label>
  );
}
