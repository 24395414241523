import useBanners from "./Banners.hook";
import Button from "../../components/Button/Button";
import "./Banners.css";
import Lists from "./common/Lists";
import HomeButton from "../../components/HomeButton";

export default function Banners() {
  const { states, setters, handlers } = useBanners();

  if (states.editMode) {
    return (
      <div key="editBanner">
        <HomeButton />
        <div id="Banners">
          <form
            ref={states.formRef}
            className="column gridFlow"
            onSubmit={(e) => handlers.handleUpdate(e)}
          >
            <div>
              <h1>Edit Banner</h1>
            </div>
            <div className="items gridFlow">
              <div className="bannerType">
                <div className="isActive">
                  <input
                    type="radio"
                    name="option"
                    id="announcement"
                    defaultChecked={states.bannerToEdit.type === "announcement"}
                  />
                  <label htmlFor="announcement">Announcement</label>
                </div>
                <div className="isActive">
                  <input
                    type="radio"
                    name="option"
                    id="error"
                    defaultChecked={states.bannerToEdit.type === "error"}
                  />
                  <label htmlFor="error">Error</label>
                </div>
              </div>
              <div>
                <label htmlFor="name">Name:</label>
                <input
                  id="name"
                  className="inputField"
                  placeholder="e.g. Something"
                  defaultValue={states.bannerToEdit.name}
                />
              </div>
              <div>
                <label htmlFor="description">Description:</label>
                <input
                  id="description"
                  className="inputField"
                  placeholder="Describe something"
                  defaultValue={states.bannerToEdit.descriptionText}
                />
              </div>
              <div>
                <label htmlFor="buttonText">Button Text:</label>
                <input
                  id="buttonText"
                  className="inputField"
                  placeholder="Something"
                  defaultValue={states.bannerToEdit.buttonText}
                />
              </div>
              <div>
                <label htmlFor="link">Link:</label>
                <input
                  id="link"
                  className="inputField"
                  placeholder="Something"
                  defaultValue={states.bannerToEdit.link}
                />
              </div>
              <div>
                <div className="isActive">
                  <input
                    type="checkbox"
                    id="isActive"
                    defaultChecked={states.bannerToEdit.isActive}
                  />
                  <label htmlFor="isActive">Is Active</label>
                </div>
              </div>
            </div>
            <div>
              <Button type="submit" width="200px">
                Edit
              </Button>
              <Button
                type="reset"
                width="200px"
                className="btn dangerBtn"
                handleClick={() => {
                  states.formRef.current.reset();
                  handlers.dispatch(
                    setters.setBannerToEdit({ editMode: false })
                  );
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
          <Lists />
        </div>
      </div>
    );
  }

  return (
    <>
      <HomeButton />
      <div id="Banners">
        <form
          ref={states.formRef}
          className="column gridFlow"
          onSubmit={(e) => handlers.handleSubmit(e)}
        >
          <div>
            <h1>Create Banner</h1>
          </div>
          <div className="items gridFlow">
            <div className="bannerType">
              <div className="isActive">
                <input
                  type="radio"
                  name="option"
                  id="announcement"
                  defaultChecked={false}
                />
                <label htmlFor="announcement">Announcement</label>
              </div>
              <div className="isActive">
                <input
                  type="radio"
                  name="option"
                  id="error"
                  defaultChecked={false}
                />
                <label htmlFor="error">Error</label>
              </div>
            </div>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                className="inputField"
                placeholder="e.g. Something"
                defaultValue=""
              />
            </div>
            <div>
              <label htmlFor="description">Description:</label>
              <input
                id="description"
                className="inputField"
                placeholder="Describe something"
                defaultValue=""
              />
            </div>
            <div>
              <label htmlFor="buttonText">Button Text:</label>
              <input
                id="buttonText"
                className="inputField"
                placeholder="Something"
                defaultValue=""
              />
            </div>
            <div>
              <label htmlFor="link">Link:</label>
              <input
                id="link"
                className="inputField"
                placeholder="Something"
                defaultValue=""
              />
            </div>
            <div>
              <div className="isActive">
                <input type="checkbox" id="isActive" defaultChecked={false} />
                <label htmlFor="isActive">Is Active</label>
              </div>
            </div>
          </div>
          <div>
            <Button type="submit" width="200px">
              Create
            </Button>
          </div>
        </form>
        <Lists />
      </div>
    </>
  );
}
