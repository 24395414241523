import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { createCoupon } from "../../services/api/coupons";
import { getPlans } from "../../services/api/api";
import { notifyLoading, notifyUpdate } from "../../utilities/utils";

export default function useCoupons() {
  let toastId;
  let selectedPlans = [];
  const [plans, setPlans] = useState([]);
  const queryClient = useQueryClient();
  const { status, data, error } = useQuery("plans", getPlans);
  const mutation = useMutation(createCoupon, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("coupons");
      res
        ? notifyUpdate(toastId, "Created Successfully", "success")
        : notifyUpdate(toastId, "Failed to Create", "error");
    },
  });

  useEffect(() => {
    if (status === "success" && data) {
      setPlans(
        data.map((plan) => {
          return {
            value: plan.planId,
            label: plan.name,
          };
        })
      );
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let discountType;
    if (e.currentTarget.monthly.checked) {
      discountType = 1;
    }
    if (e.currentTarget.yearly.checked) {
      discountType = 12;
    }
    await mutation.mutateAsync({
      code: e.currentTarget.code.value,
      minMonths: discountType,
      maxMonths: discountType,
      minUsers: e.currentTarget.minUsers.value,
      maxUsers: e.currentTarget.maxUsers.value,
      discountPercentage: Number(e.currentTarget.discountPercentage.value),
      duration_in_months_for_the_discount:
        e.currentTarget.discountDuration.value,
      plans: selectedPlans.map((plan) => plan.value),
      active: e.currentTarget.isActive.checked,
      isOnce: e.currentTarget.isOnce.checked,
    });
  };

  return {
    states: { plans, selectedPlans, status, data, error },
    handlers: { handleSubmit },
  };
}
