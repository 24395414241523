import { useState, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import {
  filterKeysAndValues,
  getCountry,
  notifyError,
  notifyLoading,
  notifyUpdate,
} from "../../utilities/utils";
import { createOrgPlan, getCurrencies } from "../../services/api/api";
import {
  CREATE_PLAN_STATE,
  CREATE_PLAN_STATE_TO_CHECK,
  PERMISSIONS_WITH_ACTIONS,
  PERMISSIONS_WITHOUT_ACTIONS,
} from "../../constants";

export default function useOrganizationPlan() {
  let toastId;
  const {
    data: currencies,
    status,
    error,
  } = useQuery("currencies", getCurrencies);
  const mutation = useMutation(createOrgPlan, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Created Successfully", "success")
        : notifyUpdate(toastId, "Failed to Create", "error");
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Create", "error");
    },
  });

  const [state, setState] = useState(CREATE_PLAN_STATE);
  const [permissionsWithActions, setPermissionsWithActions] = useState(
    PERMISSIONS_WITH_ACTIONS
  );
  const [permissionsWithoutActions, setPermissionsWithoutActions] = useState(
    PERMISSIONS_WITHOUT_ACTIONS
  );

  const onChange = useCallback(
    (value, id) => {
      const dataToUpdate = new Map(Object.entries(state));
      if (id === "publicApi") {
        const publicApi = dataToUpdate
          .get("permissions")
          .find((permission) => permission.name == "PublicApi");
        if (!publicApi && value)
          dataToUpdate.set("permissions", [
            ...dataToUpdate.get("permissions"),
            { name: "PublicApi" },
          ]);
        else
          dataToUpdate.set(
            "permissions",
            dataToUpdate
              .get("permissions")
              .filter((permission) => permission.name !== "PublicApi")
          );
      } else {
        dataToUpdate.set(id, value);
      }
      setState(Object.fromEntries(dataToUpdate));
    },
    [state]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const tempPWA = permissionsWithActions.map((permission) => {
        return {
          ...permission,
          actions: permission.actions
            .filter((action) => action.checked)
            .map((action) => action.action),
        };
      });
      const tempPWOA = permissionsWithoutActions
        .filter((permission) => permission.checked)
        .map((permission) => {
          const { checked, ...rest } = permission; // eslint-disable-line
          return rest;
        });
      const dataToSend = {
        ...state,
        countries: getCountry(state.currency),
        yearlyPricePerSeat: state.pricePerSeat,
        isDefault: true,
      };
      dataToSend.permissions = [...tempPWA, ...tempPWOA];
      for (const prop in dataToSend) {
        if (dataToSend[prop] === -1) {
          dataToSend[prop] = 10000;
        }
      }
      const dataToCheck = new Map(Object.entries(dataToSend));

      const results = filterKeysAndValues(
        dataToCheck,
        CREATE_PLAN_STATE_TO_CHECK
      );
      if (results.length) {
        notifyError("Required fields are missing");
      } else {
        mutation.mutate(dataToSend);
      }
    },
    [state, permissionsWithActions, permissionsWithoutActions, mutation]
  );

  return {
    states: {
      state,
      currencies,
      status,
      error,
      permissionsWithActions,
      permissionsWithoutActions,
    },
    setters: { setPermissionsWithActions, setPermissionsWithoutActions },
    handlers: { onChange, onSubmit },
  };
}
