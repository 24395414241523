import "./Modal.css";
import { useEffect } from "react";
import ReactDom from "react-dom";

export default function Modal({ open, children, handleClose }) {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!open) return null;

  return ReactDom.createPortal(
    <div id="Modal">
      <div className="overlay" onClick={handleClose} />
      <div className="modalContainer">
        <img
          src="/assets/images/xIcon.svg"
          className="closeModal"
          onClick={handleClose}
        />
        {children}
      </div>
    </div>,
    document.body
  );
}
