import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <div>
        <Link to="/CreateOrganizationPlan/">Create Organization Plan</Link>
      </div>
      <div>
        <Link to="/UpdateOrganizationPlan/64882dc8a9a11e8a6bedbf55">
          Update Organization Plan
        </Link>
      </div>
      <div>
        <Link to="/Details/OrganizationDetails/mohammed.cherif@kaspr.io">
          Organization Details
        </Link>
      </div>
      <div>
        <Link to="/Details/UserDetails/mohammed.cherif@kaspr.io">
          User Details
        </Link>
      </div>
      <div>
        <Link to="/RewardCode">Reward Code</Link>
      </div>
      <div>
        <Link to="/Banners">Banners</Link>
      </div>
      <div>
        <Link to="/Coupons">Coupons</Link>
      </div>
      <div>
        <Link to="/PersonalCreditsUsage?email=ahmed@kaspr.io">
          PersonalCreditsUsage
        </Link>
      </div>
      <div>
        <Link to="/OrganizationCreditsUsage?email=ahmed@kaspr.io">
          OrganizationCreditsUsage
        </Link>
      </div>
      <div>
        <Link to="/AddMember/64882dc8a9a11e8a6bedbf55">Add Member</Link>
      </div>
      <div>
        <Link to="/RestoreOrganizations">Restore Organizations</Link>
      </div>
    </div>
  );
}
