import { createSlice } from "@reduxjs/toolkit";

export const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    editMode: false,
    bannerToEdit: undefined,
  },
  reducers: {
    setBannerToEdit: (state, action) => {
      state.editMode = action.payload.editMode;
      state.bannerToEdit = action.payload.bannerToEdit;
    },
  },
});

export const { setBannerToEdit } = bannerSlice.actions;

export default bannerSlice.reducer;
