import { useState, useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { notifyLoading, notifyUpdate } from "../../utilities/utils";
import { addMember } from "../../services/api/api";
import {
  ADD_MEMBER_STATE,
  PERMISSIONS_WITH_ACTIONS_ADD_MEMBER,
} from "../../constants";

export default function useAddMember() {
  let toastId;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const email = queryParams.get("email");

  const mutation = useMutation(addMember, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Added Successfully", "success")
        : notifyUpdate(toastId, "Failed to Add", "error");
      setTimeout(() => {
        queryClient.invalidateQueries("orgDetails");
        navigate(`/Details/OrganizationDetails/${email}`);
      }, 2000);
    },
    onError: (res) => {
      notifyUpdate(toastId, res ? res.message : "Failed to Add", "error");
    },
  });

  const [state, setState] = useState(ADD_MEMBER_STATE);
  const [permissionsWithActions, setPermissionsWithActions] = useState(
    PERMISSIONS_WITH_ACTIONS_ADD_MEMBER
  );

  const onChange = useCallback(
    (value, id) => {
      const dataToUpdate = new Map(Object.entries(state));
      if (
        id === "email" ||
        id === "admin" ||
        id === "firstName" ||
        id === "lastName" ||
        id === "job"
      ) {
        dataToUpdate.set(id, value);
      } else {
        dataToUpdate.set("limit", {
          ...dataToUpdate.get("limit"),
          [id]: value,
        });
      }
      setState(Object.fromEntries(dataToUpdate));
    },
    [state]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const tempPWA = permissionsWithActions.map((permission) => {
        return {
          ...permission,
          actions: permission.actions
            .filter((action) => action.checked)
            .map((action) => action.action),
        };
      });

      const dataToSend = {
        ...state,
        ...params,
        type: `${state.admin ? "admin" : "member"}`,
      };
      for (const prop in dataToSend.limit) {
        if (dataToSend.limit[prop] === 0) {
          dataToSend.limit[prop] = -1;
        }
      }
      if (dataToSend.type === "admin") {
        dataToSend.permissions = [
          ...tempPWA,
          { actions: [], name: "Administration" },
        ];
      } else {
        dataToSend.permissions = [...tempPWA];
      }
      mutation.mutate(dataToSend);
    },
    [state, permissionsWithActions, mutation]
  );

  return {
    states: { state, permissionsWithActions },
    setters: { setPermissionsWithActions },
    handlers: { onChange, onSubmit },
  };
}
