import Select from "react-select";
import Button from "../../components/Button/Button";
import "./Coupons.css";
import Lists from "./common/Lists";
import { FullPageLoader } from "../../components/Loader";
import ErrorPortal from "../../components/ErrorPortal/ErrorPortal";
import HomeButton from "../../components/HomeButton";
import useCoupons from "./Coupons.hook";

export default function Coupons() {
  const { states, handlers } = useCoupons();

  if (states.status === "loading") return <FullPageLoader />;

  if (
    states.error ||
    (states.data && states.data.statusCode === 400) ||
    (states.status === "success" && !states.data)
  ) {
    return (
      <ErrorPortal
        title="Create Coupons"
        message="Some error occurred while fetching the data"
      />
    );
  }

  return (
    <>
      <HomeButton />
      <div id="Coupons">
        <form
          className="column gridFlow"
          onSubmit={(e) => handlers.handleSubmit(e)}
        >
          <div>
            <h1>Create Coupons</h1>
          </div>
          <div className="items gridFlow">
            <div className="bannerType">
              <div className="isActive">
                <input type="radio" name="option" id="monthly" />
                <label htmlFor="monthly">Monthly</label>
              </div>
              <div className="isActive">
                <input type="radio" name="option" id="yearly" />
                <label htmlFor="yearly">Yearly</label>
              </div>
            </div>
            <div>
              <label htmlFor="code">Code:</label>
              <input
                id="code"
                className="inputField"
                placeholder="e.g. Halloween"
              />
            </div>
            <div>
              <label htmlFor="minUsers">Minimum Users:</label>
              <input
                id="minUsers"
                className="inputField"
                placeholder="Minimum Number of Users"
                type="number"
              />
            </div>
            <div>
              <label htmlFor="maxUsers">Maximum Users:</label>
              <input
                id="maxUsers"
                className="inputField"
                placeholder="Maximum Number of Users"
                type="number"
              />
            </div>
            <div>
              <label htmlFor="discountPercentage">Discount:</label>
              <input
                id="discountPercentage"
                className="inputField"
                placeholder="Percentage Number"
                type="number"
              />
            </div>
            <div>
              <label htmlFor="discountDuration">Discount Duration:</label>
              <input
                id="discountDuration"
                className="inputField"
                placeholder="Number of Months"
                type="number"
              />
            </div>
            <div>
              <label>Plans:</label>
              <Select
                isMulti
                name="plans"
                options={states.plans}
                className="selectField"
                placeholder="Select Plans"
                closeMenuOnSelect={false}
                onChange={(plan) => (states.selectedPlans = plan)}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary: "darkcyan",
                    primary50: "darkcyan",
                    primary25: "cadetblue",
                    danger: "darkred",
                    neutral10: "cadetblue",
                    neutral20: "#ced4da",
                    neutral80: "black",
                  },
                })}
              />
            </div>
            <div>
              <div className="isActive">
                <input type="checkbox" id="isActive" />
                <label htmlFor="isActive">Is Active</label>
              </div>
            </div>
            <div>
              <div className="isActive">
                <input type="checkbox" id="isOnce" />
                <label htmlFor="isOnce">Apply Once</label>
              </div>
            </div>
          </div>
          <div>
            <Button type="submit" width="200px">
              Create
            </Button>
          </div>
        </form>
        <Lists />
      </div>
    </>
  );
}
