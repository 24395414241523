import { forwardRef } from "react";
import "./Input.css";

const CustomInput = forwardRef(
  (
    {
      label,
      defaultValue,
      value,
      disabled,
      onChange,
      customStyles,
      placeHolder,
    },
    ref
  ) => {
    return (
      <div className={"fieldGroup"}>
        <label htmlFor={label}>{label}</label>
        <input
          className="field"
          style={customStyles}
          id={label && label.replaceAll(" ", "")}
          placeholder={placeHolder ?? label}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
        />
      </div>
    );
  }
);

const CustomTextArea = forwardRef(
  ({ label, defaultValue, value, disabled, onChange }, ref) => (
    <div id="comment">
      <div className={"fieldGroup"}>
        <label htmlFor={label}>{label}</label>
        <textarea
          className="field"
          id={label && label.replaceAll(" ", "")}
          placeholder={label}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
        />
      </div>
    </div>
  )
);

const CustomCheckbox = forwardRef(
  ({ label, defaultValue, checked, disabled, onChange, customStyles }, ref) => {
    return (
      <div className="form-group">
        <input
          type="checkbox"
          style={customStyles}
          id={label && label.replaceAll(" ", "")}
          defaultChecked={defaultValue}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
        />
        <label htmlFor={label && label.replaceAll(" ", "")}>{label}</label>
      </div>
    );
  }
);

export default CustomInput;
export { CustomTextArea, CustomCheckbox };
