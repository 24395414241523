import { useEffect, useRef } from "react";
import "./DropdownBoard.css";

export default function DropdownBoard({
  exportMenu,
  onOutsideClick,
  children,
}) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div
      id={exportMenu ? "DropdownBoardExport" : "DropdownBoard"}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
}
