import { createSlice } from "@reduxjs/toolkit";

export const deleteMemberSlice = createSlice({
  name: "deleteMember",
  initialState: {
    showDeleteMemberModal: false,
  },
  reducers: {
    setMember: (state, action) => {
      state.member = action.payload.email;
      state.orgId = action.payload.organizationId;
      state.showDeleteMemberModal = action.payload.showDeleteMemberModal;
    },
  },
});

export const { setMember } = deleteMemberSlice.actions;

export default deleteMemberSlice.reducer;
