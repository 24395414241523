import { convertIdOrEmail, dataAdjustment } from "../../utilities/utils";
import { API_URL } from "../../constants";

export const getOrgPlan = (orgId) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/organization/plan/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orgId),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve({
            orgId: data.organizationCredits.organizationId,
            ...dataAdjustment(data),
          });
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const updateOrgPlan = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/organization/plan/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const createOrgPlan = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/custom/plan`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const attachOrgPlan = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/organization/plan/set`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getOrgMembers = async (payload) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/organization/members`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getOrgDetails = async (queryObj) => {
  const query = convertIdOrEmail(queryObj.email);
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getUserDetails = async (orgEmail) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/user/infos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orgEmail),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const updateOrgCredit = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/credits/addOrganizationCredits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const updateUserCredit = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/credits/addPersonalCredits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getPlans = async () => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/plans/all`)
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getCoupons = async () => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/credits/coupon/all`)
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const suspendOrg = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(
      `${API_URL}/commercial/organization/suspendAndActivateSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const unsubscribeOrg = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/unsubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const blockUser = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/block`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const unblockUser = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/unblock`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const deleteUser = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/user`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const restoreUser = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/user/activate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const addMember = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/commercial/member/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const updateMember = async (data) => {
  try {
    const response = await fetch(`${API_URL}/organization/member/updateInfos`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    const contentType = response.headers.get("Content-Type");
    if (
      !response.ok ||
      (contentType && !contentType.includes("application/json"))
    ) {
      const error = new Error("Response is not in JSON format");
      error.statusCode = 400;
      throw error;
    }
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export const deleteMember = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/organization/member/leave`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getCurrencies = async () => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/credits/plan/supportedCurrencies`)
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const renewPlan = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/credits/subscription/renew`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const cancelInvitation = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/organization/invitation/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const getSuspendedOrg = async (payload) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/organization/suspension/getMembers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const RestoreSuspendedOrg = async (data) => {
  // ********* Mock successful promise *********
  // return new Promise((resolve, reject) => {
  //   const mockResponse = {
  //     status: 200,
  //     json: () =>
  //       Promise.resolve({
  //         message: "Organization suspension restored successfully",
  //       }),
  //   };

  //   setTimeout(() => resolve(mockResponse), 1000);
  // });

  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/organization/suspension/restore`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.json();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};

export const exportCreditsUsage = async (payload) => {
  return new Promise((resolve, reject) => {
    return fetch(`${API_URL}/credits/activity/exportStatsOrUsages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const data = await response.blob();
          resolve(data);
        } else {
          const message = await response.json();
          reject({ ...message });
        }
      })
      .catch((err) => {
        console.error("catch", err);
        return err;
      });
  });
};
