let API_URL = "http://staging.api.kaspr.io:8070";
// let API_URL = "http://localhost:8070";
//const API_URL = "http://app.kaspr.io:8070"

/* eslint-disable */
if (process.env.REACT_APP_ENVIRONMENT === "preprod") {
  API_URL = "https://backoffice.kaspr.io";
}

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  API_URL = "https://backoffice.kaspr.io:99";
}


if (process.env.REACT_APP_ENVIRONMENT === "dev") {
  API_URL = "https://dev.backoffice.kaspr.io";
}
/* eslint-disable */

const ALL_ACTIONS = [
  {
    name: "Tasks",
    actions: [
      { action: "Create", reference: "CreateTasks" },
      { action: "Edit", reference: "EditTasks" },
      { action: "Delete", reference: "DeleteTasks" },
    ],
  },
  {
    name: "Notes",
    actions: [
      { action: "Create", reference: "CreateNotes" },
      { action: "Edit", reference: "EditNotes" },
      { action: "Delete", reference: "DeleteNotes" },
    ],
  },
  {
    name: "WaitingList",
    actions: [
      { action: "View", reference: "ViewWaitingList" },
      { action: "Edit", reference: "EditWaitingList" },
    ],
  },
  {
    name: "MemberPermission",
    actions: [
      { action: "View", reference: "ViewMemberPermission" },
      { action: "Edit", reference: "EditMemberPermission" },
    ],
  },
  {
    name: "TeamActivity",
    actions: [
      { action: "View", reference: "ViewTeamActivity" },
      { action: "Export", reference: "ExportTeamActivity" },
    ],
  },
  {
    name: "TeamReports",
    actions: [
      { action: "View", reference: "ViewTeamReports" },
      { action: "Export", reference: "ExportTeamReports" },
    ],
  },
  {
    name: "Leads",
    actions: [
      { action: "View", reference: "ViewLeads" },
      { action: "Edit", reference: "EditLeads" },
      { action: "Save", reference: "SaveLeads" },
      { action: "Export", reference: "ExportLeads" },
    ],
  },
  {
    name: "Tags",
    actions: [
      { action: "View", reference: "ViewTags" },
      { action: "Edit", reference: "EditTags" },
      { action: "Save", reference: "SaveTags" },
    ],
  },
  {
    name: "SupportedWebsites",
    actions: [
      { action: "Linkedin", reference: "LinkedinSupportedWebsites" },
      {
        action: "SalesNavigator",
        reference: "SalesNavigatorSupportedWebsites",
      },
      { action: "Recruiter", reference: "RecruiterSupportedWebsites" },
      { action: "Allwebsites", reference: "AllwebsitesSupportedWebsites" },
    ],
  },
  {
    name: "Integration",
    actions: [
      { action: "Hubspot", reference: "HubspotIntegration" },
      {
        action: "SalesforceContact",
        reference: "SalesforceContactIntegration",
      },
      { action: "SalesforceLead", reference: "SalesforceLeadIntegration" },
      { action: "Gmail", reference: "GmailIntegration" },
      { action: "Pipedrive", reference: "PipedriveIntegration" },
      { action: "Zapier", reference: "ZapierIntegration" },
      { action: "Sendinblue", reference: "SendinblueIntegration" },
      { action: "Lemlist", reference: "LemlistIntegration" },
      { action: "Ringover", reference: "RingoverIntegration" },
      { action: "Aircall", reference: "AircallIntegration" },
      { action: "Templates", reference: "TemplatesIntegration" },
      { action: "Zoho-crm", reference: "Zoho-crmIntegration" },
    ],
  },
  {
    name: "Workflow",
    actions: [
      { action: "View", reference: "ViewWorkflow" },
      { action: "Edit", reference: "EditWorkflow" },
      { action: "Execute", reference: "ExecuteWorkflow" },
    ],
  },
  {
    name: "Sequence",
    actions: [
      { action: "View", reference: "ViewSequence" },
      { action: "Edit", reference: "EditSequence" },
      { action: "Execute", reference: "ExecuteSequence" },
      { action: "linkedinOutreach", reference: "linkedinOutreachSequence" },
      { action: "Email", reference: "EmailSequence" },
      { action: "Task", reference: "TaskSequence" },
      { action: "Templates", reference: "TemplatesSequence" },
    ],
  },
  {
    name: "SupportedAutomationTypes",
    actions: [
      { action: "Linkedin", reference: "LinkedinSupportedAutomationTypes" },
      {
        action: "SalesNavigator",
        reference: "SalesNavigatorSupportedAutomationTypes",
      },
      { action: "Recruiter", reference: "RecruiterSupportedAutomationTypes" },
      {
        action: "LinkedinInvitations",
        reference: "LinkedinInvitationsSupportedAutomationTypes",
      },
      {
        action: "LinkedinMessages",
        reference: "LinkedinMessagesSupportedAutomationTypes",
      },
      {
        action: "LinkedinFilteredSearch",
        reference: "LinkedinFilteredSearchSupportedAutomationTypes",
      },
      {
        action: "LinkedinPost",
        reference: "LinkedinPostSupportedAutomationTypes",
      },
      {
        action: "LinkedinEvent",
        reference: "LinkedinEventSupportedAutomationTypes",
      },
      {
        action: "LinkedinGroup",
        reference: "LinkedinGroupSupportedAutomationTypes",
      },
    ],
  },
  {
    name: "Support",
    actions: [
      {
        action: "Dedicated Account Manager",
        reference: "DedicatedAccountManagerSupport",
      },
      {
        action: "Chat",
        reference: "ChatSupport",
      },
    ],
  },
  {
    name: "Compliance",
    actions: [
      {
        action: "GDPR & CCPA aligned",
        reference: "GDPR&CCPAalignedCompliance",
      },
    ],
  },
];

const ALL_ACTIONS_ADD_MEMBER = [
  {
    name: "Leads",
    actions: [
      { action: "View", reference: "ViewLeads" },
      { action: "Edit", reference: "EditLeads" },
      { action: "Save", reference: "SaveLeads" },
      { action: "Export", reference: "ExportLeads" },
    ],
  },
  {
    name: "Integration",
    actions: [
      { action: "Export", reference: "ExportIntegration" },
      { action: "Mapping", reference: "MappingIntegration" },
      { action: "Sync", reference: "SyncIntegration" },
    ],
  },
  {
    name: "Workflow",
    actions: [
      { action: "View", reference: "ViewWorkflow" },
      { action: "Edit", reference: "EditWorkflow" },
      { action: "Execute", reference: "ExecuteWorkflow" },
    ],
  },
];

const CREATE_PLAN_STATE = {
  legacyCredits: {
    perSeat: 0,
    recurrent: 0,
    extra: 0,
    recurrencePriority: 10,
  },
  workEmailCreditsPerSeat: "",
  personalEmailCreditsPerSeat: "",
  phoneCreditsPerSeat: "",
  exportCreditsPerSeat: "",
  isCumulative: true,
  minSeats: "",
  maxSeats: "",
  numberOfMonths: "",
  maxAdmins: "",
  numberOfWorkflow: "",
  numberOfSequences: "",
  numberOfWorkflowLaunches: "",
  contactPerLaunch: "",
  maxLists: "",
  maxTags: "",
  coupon: null,
  version: 2,
  planId: "",
  name: "",
  pricePerSeat: "",
  numberOfSeats: "",
  currency: "EUR",
  recurringAddedCredits: {
    legacyCredits: 0,
    workEmailCredits: 0,
    personalEmailCredits: 0,
    phoneCredits: 0,
    exportCredits: 0,
  },
};

const CREATE_PLAN_STATE_TO_CHECK = [
  "planId",
  "name",
  "numberOfWorkflow",
  "numberOfSequences",
  "numberOfWorkflowLaunches",
  "contactPerLaunch",
  "workEmailCreditsPerSeat",
  "personalEmailCreditsPerSeat",
  "phoneCreditsPerSeat",
  "exportCreditsPerSeat",
  "pricePerSeat",
  "numberOfSeats",
  "numberOfMonths",
  "minSeats",
  "maxSeats",
  "maxLists",
  "maxTags",
  "maxAdmins",
];

const ADD_MEMBER_STATE = {
  email: "",
  lastName: "",
  firstName: "",
  job: "",
  limit: {
    legacyCredits: -1,
    workEmailCredits: -1,
    personalEmailCredits: -1,
    phoneCredits: -1,
    exportCredits: -1,
  },
  admin: true,
};

const PERMISSIONS_WITH_ACTIONS = [
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewTeamActivity",
      },
      {
        checked: true,
        action: "Export",
        reference: "ExportTeamActivity",
      },
    ],
    limited: false,
    name: "TeamActivity",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewTeamReports",
      },
      {
        checked: true,
        action: "Export",
        reference: "ExportTeamReports",
      },
    ],
    limited: false,
    name: "TeamReports",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewLeads",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditLeads",
      },
      {
        checked: true,
        action: "Save",
        reference: "SaveLeads",
      },
      {
        checked: true,
        action: "Export",
        reference: "ExportLeads",
      },
    ],
    limited: false,
    name: "Leads",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewTags",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditTags",
      },
      {
        checked: true,
        action: "Save",
        reference: "SaveTags",
      },
    ],
    limited: false,
    name: "Tags",
  },
  {
    actions: [
      {
        checked: true,
        action: "Create",
        reference: "CreateTasks",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditTasks",
      },
      {
        checked: true,
        action: "Delete",
        reference: "DeleteTasks",
      },
    ],
    limited: false,
    name: "Tasks",
  },
  {
    actions: [
      {
        checked: true,
        action: "Create",
        reference: "CreateNotes",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditNotes",
      },
      {
        checked: true,
        action: "Delete",
        reference: "DeleteNotes",
      },
    ],
    limited: false,
    name: "Notes",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewWaitingList",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditWaitingList",
      },
    ],
    limited: false,
    name: "WaitingList",
  },
  {
    actions: [
      {
        checked: true,
        action: "Linkedin",
        reference: "LinkedinSupportedWebsites",
      },
      {
        checked: true,
        action: "SalesNavigator",
        reference: "SalesNavigatorSupportedWebsites",
      },
      {
        checked: true,
        action: "Recruiter",
        reference: "RecruiterSupportedWebsites",
      },
      {
        checked: true,
        action: "Allwebsites",
        reference: "AllwebsitesSupportedWebsites",
      },
    ],
    limited: false,
    name: "SupportedWebsites",
  },
  {
    actions: [
      {
        checked: true,
        action: "Hubspot",
        reference: "HubspotIntegration",
      },
      {
        checked: true,
        action: "SalesforceContact",
        reference: "SalesforceContactIntegration",
      },
      {
        checked: true,
        action: "SalesforceLead",
        reference: "SalesforceLeadIntegration",
      },
      {
        checked: true,
        action: "Gmail",
        reference: "GmailIntegration",
      },
      {
        checked: true,
        action: "Pipedrive",
        reference: "PipedriveIntegration",
      },
      {
        checked: true,
        action: "Zapier",
        reference: "ZapierIntegration",
      },
      {
        checked: true,
        action: "Sendinblue",
        reference: "SendinblueIntegration",
      },
      {
        checked: true,
        action: "Lemlist",
        reference: "LemlistIntegration",
      },
      {
        checked: true,
        action: "Ringover",
        reference: "RingoverIntegration",
      },
      {
        checked: true,
        action: "Aircall",
        reference: "AircallIntegration",
      },
      {
        checked: true,
        action: "Templates",
        reference: "TemplatesIntegration",
      },
      {
        checked: true,
        action: "Zoho-crm",
        reference: "Zoho-crmIntegration",
      },
    ],
    limited: false,
    name: "Integration",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewWorkflow",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditWorkflow",
      },
      {
        checked: true,
        action: "Execute",
        reference: "ExecuteWorkflow",
      },
    ],
    limited: false,
    name: "Workflow",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewSequence",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditSequence",
      },
      {
        checked: true,
        action: "Execute",
        reference: "ExecuteSequence",
      },
      {
        checked: true,
        action: "linkedinOutreach",
        reference: "linkedinOutreachSequence",
      },
      {
        checked: true,
        action: "Email",
        reference: "EmailSequence",
      },
      {
        checked: true,
        action: "Task",
        reference: "TaskSequence",
      },
      {
        checked: true,
        action: "Templates",
        reference: "TemplatesSequence",
      },
    ],
    limited: false,
    name: "Sequence",
  },
  {
    actions: [
      {
        checked: true,
        action: "Linkedin",
        reference: "LinkedinSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "SalesNavigator",
        reference: "SalesNavigatorSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "Recruiter",
        reference: "RecruiterSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinInvitations",
        reference: "LinkedinInvitationsSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinMessages",
        reference: "LinkedinMessagesSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinFilteredSearch",
        reference: "LinkedinFilteredSearchSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinPost",
        reference: "LinkedinPostSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinEvent",
        reference: "LinkedinEventSupportedAutomationTypes",
      },
      {
        checked: true,
        action: "LinkedinGroup",
        reference: "LinkedinGroupSupportedAutomationTypes",
      },
    ],
    limited: false,
    name: "SupportedAutomationTypes",
  },
  {
    actions: [
      {
        checked: true,
        action: "Dedicated Account Manager",
        reference: "Dedicated Account ManagerSupport",
      },
      {
        checked: true,
        action: "Chat",
        reference: "ChatSupport",
      },
    ],
    limited: false,
    name: "Support",
  },
  {
    actions: [
      {
        checked: true,
        action: "GDPR & CCPA aligned",
        reference: "GDPR & CCPA alignedCompliance",
      },
    ],
    limited: false,
    name: "Compliance",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewMemberPermission",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditMemberPermission",
      },
    ],
    limited: false,
    name: "MemberPermission",
  },
];

const PERMISSIONS_WITHOUT_ACTIONS = [
  { name: "HideGenericsEmails", actions: Array(0), checked: true },
  { name: "PublicApi", actions: Array(0), checked: true },
];

const PERMISSIONS_WITH_ACTIONS_ADD_MEMBER = [
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewLeads",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditLeads",
      },
      {
        checked: true,
        action: "Save",
        reference: "SaveLeads",
      },
      {
        checked: true,
        action: "Export",
        reference: "ExportLeads",
      },
    ],
    limited: true,
    name: "Leads",
  },
  {
    actions: [
      {
        checked: true,
        action: "Export",
        reference: "ExportIntegration",
      },
      {
        checked: true,
        action: "Mapping",
        reference: "MappingIntegration",
      },
      {
        checked: true,
        action: "Sync",
        reference: "SyncIntegration",
      },
    ],
    limited: true,
    name: "Integration",
  },
  {
    actions: [
      {
        checked: true,
        action: "View",
        reference: "ViewWorkflow",
      },
      {
        checked: true,
        action: "Edit",
        reference: "EditWorkflow",
      },
      {
        checked: true,
        action: "Execute",
        reference: "ExecuteWorkflow",
      },
    ],
    limited: true,
    name: "Workflow",
  },
];

export {
  ALL_ACTIONS,
  ALL_ACTIONS_ADD_MEMBER,
  CREATE_PLAN_STATE,
  CREATE_PLAN_STATE_TO_CHECK,
  ADD_MEMBER_STATE,
  PERMISSIONS_WITH_ACTIONS,
  PERMISSIONS_WITHOUT_ACTIONS,
  PERMISSIONS_WITH_ACTIONS_ADD_MEMBER,
  API_URL,
};
