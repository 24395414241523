import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CreditsTypeCell from "../../pages/Details/common/CreditsTypeCell/CreditsTypeCell";
import SearchMembers from "../../pages/Details/common/MembersList/SearchMembers";
import PermissionsCell from "../../pages/Details/common/PermissionsCell/PermissionsCell";
import Pagination from "../Pagination/Pagination";
import { getEmojiRole } from "../../utilities/utils";
import "./Table.css";
import { setMember } from "../../pages/Details/deleteMemberSlice";
import { setInvitationToCancel } from "../../pages/Details/common/InvitationsList/cancelInvitationSlice";
import { memberToRestore } from "../../pages/RestoreOrganizations/memberToRestoreSlice";

export default function Table({
  title,
  data,
  dataKeys,
  tableHeads,
  invitations,
  creditsUsageTable,
  restoreWorkspace,
  members,
  maxAdmins,
  totalAdmins,
  openRoleModal,
  paginationData,
  searchInputRef,
  handleSearch,
}) {
  const orgId = useSelector((state) => state.orgId.orgId);
  const dispatch = useDispatch();

  return (
    <table id="Table">
      {title && (
        <caption>
          {title}{" "}
          {members && (
            <SearchMembers
              searchInputRef={searchInputRef}
              handleSearch={handleSearch}
              maxAdmins={maxAdmins}
              totalAdmins={totalAdmins}
            />
          )}
        </caption>
      )}
      <thead>
        <tr>
          {tableHeads.map((head) => (
            <th key={head}>{head}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td
              colSpan="20"
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "darkred",
                borderBottomLeftRadius: 20,
              }}
            >
              No data
            </td>
          </tr>
        )}
        {data.map((d, i) => (
          <tr key={title + i}>
            {members || invitations ? (
              <th className="tableHeadContainer">
                <div className="tableHeadWrapper">
                  {data.length > 0 && !creditsUsageTable && (
                    <img
                      src="/assets/images/trashIcon.svg"
                      alt="trashIcon"
                      className="emojiRole"
                      style={{ padding: 3 }}
                      onClick={() =>
                        members
                          ? dispatch(
                              setMember({
                                email: data[i].email,
                                ...orgId,
                                showDeleteMemberModal: true,
                              })
                            )
                          : dispatch(
                              setInvitationToCancel({
                                inviteeEmail: data[i].invitee,
                                organizationOwner: data[i].inviterEmail,
                                ...orgId,
                                showCancelInvitationModal: true,
                              })
                            )
                      }
                    />
                  )}
                  <span>{i + 1}</span>
                </div>
              </th>
            ) : restoreWorkspace ? (
              <th>
                <input
                  type="radio"
                  checked={d.selected}
                  onChange={() =>
                    dispatch(
                      memberToRestore({
                        email: d.email,
                      })
                    )
                  }
                />
                {i + 1}
              </th>
            ) : (
              <th>{i + 1}</th>
            )}
            {dataKeys.map((key, j) => {
              if (!invitations && !members) {
                return <td key={key + j}>{data[i][key]}</td>;
              }
              if (invitations) {
                if (key === "limit") {
                  if (data[i]["automationType"] === "Workflow") {
                    return <td key={key + j}>Workflow</td>;
                  } else {
                    return (
                      <CreditsTypeCell
                        key={key + j}
                        credits={data[i][key]}
                        // decreased={data[i].isDecreased === "Yes" ? true : false}
                      />
                    );
                  }
                }
                if (key === "permissions") {
                  return (
                    <PermissionsCell
                      key={`invitations${key + j}`}
                      permissions={data[i][key]}
                    />
                  );
                }
                if (
                  key === "target" &&
                  data[i]["automationType"] === "Workflow"
                ) {
                  return (
                    <td key={key + j}>
                      <Link to={data[i]["searchUrl"]} target="_blank">
                        target URL
                      </Link>
                    </td>
                  );
                }
                return <td key={key + j}>{data[i][key]}</td>;
              }
              if (members) {
                if (key === "limit") {
                  return (
                    <CreditsTypeCell key={key + j} credits={data[i][key]} />
                  );
                }
                if (key === "usages") {
                  return (
                    <CreditsTypeCell key={key + j} credits={data[i][key]} />
                  );
                }
                if (key === "permissions") {
                  return (
                    <PermissionsCell
                      key={`members${key + j}`}
                      permissions={data[i][key]}
                      members
                    />
                  );
                }
                if (key === "role") {
                  return (
                    <td key={key + j}>
                      <span
                        className="emojiRole"
                        onClick={() => {
                          openRoleModal(i);
                        }}
                      >
                        {getEmojiRole(data[i][key])}
                      </span>
                      {data[i][key]}
                    </td>
                  );
                }
                return <td key={key + j}>{data[i][key]}</td>;
              }
            })}
          </tr>
        ))}
      </tbody>
      {paginationData && (
        <tfoot>
          <tr>
            <td colSpan="20">
              <Pagination
                currentPage={paginationData.currentPage}
                totalCount={paginationData.totalPages}
                pageSize={10}
                onPageChange={paginationData.onPageChange}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
}
