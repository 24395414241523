import { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { notifyLoading, notifyUpdate } from "../../../../utilities/utils";
import {
  updateOrgCredit,
  updateUserCredit,
} from "../../../../services/api/api";
import Button from "../../../../components/Button/Button";
import "./RemainingCreditsTable.css";
import { CustomTextArea } from "../../../../components/Input/Input";

export default function RemainingCreditsTable({ data, user, email }) {
  let toastId;
  const [remainingCredits, setRemainingCredits] = useState(data);
  const [remainingCreditsToSend, setRemainingCreditsToSend] = useState({});
  const commentRef = useRef(null);
  const {
    workEmailCredits,
    personalEmailCredits,
    phoneCredits,
    exportCredits,
    legacyCredits,
  } = remainingCredits;
  const queryClient = useQueryClient();
  const { mutate } = useMutation(user ? updateUserCredit : updateOrgCredit, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      user
        ? queryClient.invalidateQueries("userDetails")
        : queryClient.invalidateQueries("orgDetails");
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update", "error");
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Update", "error");
    },
  });

  useEffect(() => {
    setRemainingCredits(data);
  }, [data]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      let tempRemainingCredits = { ...remainingCredits };
      const remainingCreditsToSendMap = new Map(
        Object.entries(remainingCreditsToSend)
      );
      let credits = [];
      remainingCreditsToSendMap.forEach((creditsTypeValue, keyCredit) => {
        const valuesToSend = new Map(Object.entries(creditsTypeValue));
        valuesToSend.forEach((value, keyType) => {
          credits.push({
            creditType: keyCredit,
            amount:
              keyType === "recurrent"
                ? Number(value)
                : calc(remainingCredits[keyCredit][keyType], value),
            setRewardCreditsEventNow: true,
            ...(function () {
              if (keyType === "recurrent") {
                return { isRecurrent: true };
              }
              if (keyType === "extra") {
                return { isPack: true };
              }
            })(),
          });
          tempRemainingCredits[keyCredit][keyType] = Number(value);
        });
      });
      const dataToSend = {
        credits,
        email,
        comment: commentRef.current.value,
      };
      await mutate(dataToSend);
      setRemainingCreditsToSend({});
      setRemainingCredits(tempRemainingCredits);
    } catch (error) {
      console.error("Error updating credits:", error);
    }
  };

  return (
    <table id="remainingCreditsTable">
      <caption>Credit Types</caption>
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Amount</th>
          <th scope="col">Recurrent</th>
          {user ? null : <th scope="col">Add-on</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">B2B Email Credits</th>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="workEmailCredits"
              name="amount"
              data={workEmailCredits.amount}
            />
          </td>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="workEmailCredits"
              name="recurrent"
              data={workEmailCredits.recurrent}
            />
          </td>
          {user ? null : (
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="workEmailCredits"
                name="extra"
                data={workEmailCredits.extra}
              />
            </td>
          )}
        </tr>
        <tr>
          <th scope="row">Direct Email Credits</th>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="personalEmailCredits"
              name="amount"
              data={personalEmailCredits.amount}
            />
          </td>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="personalEmailCredits"
              name="recurrent"
              data={personalEmailCredits.recurrent}
            />
          </td>
          {user ? null : (
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="personalEmailCredits"
                name="extra"
                data={personalEmailCredits.extra}
              />
            </td>
          )}
        </tr>
        <tr>
          <th scope="row">Phone Credits</th>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="phoneCredits"
              name="amount"
              data={phoneCredits.amount}
            />
          </td>
          <td>
            <TableDataInput
              setRemainingCreditsToSend={setRemainingCreditsToSend}
              type="phoneCredits"
              name="recurrent"
              data={phoneCredits.recurrent}
            />
          </td>
          {user ? null : (
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="phoneCredits"
                name="extra"
                data={phoneCredits.extra}
              />
            </td>
          )}
        </tr>
        {user ? null : (
          <tr>
            <th scope="row">Export Lead Credits</th>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="exportCredits"
                name="amount"
                data={exportCredits.amount}
              />
            </td>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="exportCredits"
                name="recurrent"
                data={exportCredits.recurrent}
              />
            </td>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="exportCredits"
                name="extra"
                data={exportCredits.extra}
              />
            </td>
          </tr>
        )}
        {user ? null : (
          <tr>
            <th scope="row">Legacy Credits</th>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="legacyCredits"
                name="amount"
                data={legacyCredits.amount}
              />
            </td>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="legacyCredits"
                name="recurrent"
                data={legacyCredits.recurrent}
              />
            </td>
            <td>
              <TableDataInput
                setRemainingCreditsToSend={setRemainingCreditsToSend}
                type="legacyCredits"
                name="extra"
                data={legacyCredits.extra}
              />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4">
            <CustomTextArea label="Comment" ref={commentRef} />
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <Button type="submit" width="25%" handleClick={handleSave}>
              Save
            </Button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function TableDataInput({ data, type, name, setRemainingCreditsToSend }) {
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const handleChange = (e) => {
    setValue(e.target.value);
    setRemainingCreditsToSend((currentRemainingCreditsToSend) => {
      return {
        ...currentRemainingCreditsToSend,
        [type]: {
          ...currentRemainingCreditsToSend[type],
          [name]: e.target.value,
        },
      };
    });
  };

  return (
    <input
      value={value}
      type="number"
      className="inputCell"
      onChange={handleChange}
    />
  );
}

const calc = (oldVal, newVal) => {
  if (newVal < oldVal) {
    return newVal - oldVal;
  }
  if (newVal > oldVal) {
    return Math.abs(oldVal - newVal);
  }
  return null;
};
