import "./ErrorPortal.css";
import { API_URL } from "../../constants";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";

export default function ErrorPortal({ title, message }) {
  return ReactDom.createPortal(
    <div id="ErrorPortal">
      <div className="overlay" />
      <div className="modal">
        <h1 className="title">{title}</h1>
        <h3 className="message">
          {message ? message : "Something went wrong"}
        </h3>
      </div>
    </div>,
    document.body
  );
}
