import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import CreateOrganizationPlan from "./pages/CreateOrganizationPlan/CreateOrganizationPlan";
import UpdateOrganizationPlan from "./pages/UpdateOrganizationPlan/UpdateOrganizationPlan";
import Details from "./pages/Details/Details";
import RewardCode from "./pages/RewardCode/RewardCode";
import CreditsUsage from "./pages/CreditsUsage/CreditsUsage";
import AddMember from "./pages/AddMember/AddMember";
import Banners from "./pages/Banners/Banners";
import Coupons from "./pages/Coupons/Coupons";
import RestoreOrganizations from "./pages/RestoreOrganizations/RestoreOrganizations";

export default function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/CreateOrganizationPlan/"
          element={<CreateOrganizationPlan />}
        />
        <Route
          path="/UpdateOrganizationPlan/:organizationId"
          element={<UpdateOrganizationPlan />}
        />
        <Route
          path="/Details/OrganizationDetails/:email"
          element={<Details />}
        />
        <Route path="/Details/UserDetails/:email" element={<Details user />} />
        <Route path="/RewardCode" element={<RewardCode />} />
        <Route
          path="/PersonalCreditsUsage/"
          element={<CreditsUsage pageTitle="Personal Credits Usage" />}
        />
        <Route
          path="/OrganizationCreditsUsage/"
          element={<CreditsUsage pageTitle="Organization Credits Usage" />}
        />
        <Route path="/AddMember/:organizationId" element={<AddMember />} />
        <Route path="/Banners/" element={<Banners />} />
        <Route path="/Coupons/" element={<Coupons />} />
        <Route
          path="/RestoreOrganizations/"
          element={<RestoreOrganizations />}
        />
      </Routes>
    </div>
  );
}
