import ReactTooltip from "react-tooltip";
import "./AddMember.css";
import {
  SectionWrapper,
  MainWrapper,
  GroupWrapper,
} from "../../components/Wrappers";
import { Field, FormActions, Permissions } from "../../components/Tools";
import useAddMember from "./AddMember.hook";
import HomeButton from "../../components/HomeButton";

export default function AddMember() {
  const { states, setters, handlers } = useAddMember();
  const toolTip = " -1 for unlimited";

  return (
    <>
      <HomeButton />
      <div id="AddMember">
        <MainWrapper
          onSubmit={handlers.onSubmit}
          title={"Add New Member"}
          styleId="createPlan"
        >
          <SectionWrapper title={"Member settings"}>
            <GroupWrapper>
              <Field
                title={"First Name"}
                id={"firstName"}
                value={states.state.firstName}
                onChange={handlers.onChange}
              />
              <Field
                title={"Last Name"}
                id={"lastName"}
                value={states.state.lastName}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                title={"Email"}
                id={"email"}
                value={states.state.email}
                onChange={handlers.onChange}
              />
              <Field
                title={"Job"}
                id={"job"}
                value={states.state.job}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
          </SectionWrapper>
          <SectionWrapper title={"Credits limit"}>
            <GroupWrapper>
              <Field
                title={"B2B email"}
                id={"workEmailCredits"}
                type="number"
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                title={"Export"}
                id={"exportCredits"}
                type="number"
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                title={"Phone"}
                id={"phoneCredits"}
                type="number"
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
              <Field
                title={"Direct Email"}
                id={"personalEmailCredits"}
                type="number"
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
            <GroupWrapper>
              <Field
                title={"Legacy"}
                id={"legacyCredits"}
                type="number"
                toolTip={toolTip}
                min={-1}
                onChange={handlers.onChange}
              />
            </GroupWrapper>
          </SectionWrapper>
          <SectionWrapper className={"form-line"}>
            <input
              type="checkbox"
              id={"admin"}
              style={{ cursor: "pointer" }}
              onChange={(e) => handlers.onChange(e.target.checked, "admin")}
              checked={states.state.admin}
            />
            <label htmlFor="admin" style={{ cursor: "pointer" }}>
              Administrator
            </label>
          </SectionWrapper>
          <SectionWrapper
            title={"Permissions"}
            className={"permissions-wrapper"}
          >
            <Permissions
              permissionsWithActions={states.permissionsWithActions}
              setPermissionsWithActions={setters.setPermissionsWithActions}
              addMember
            />
          </SectionWrapper>
          <FormActions
            onReset={handlers.onResetState}
            onSubmit={handlers.onSubmit}
            addMember
          />
        </MainWrapper>
        <ReactTooltip effect="solid" offset={{ bottom: 7 }} />
      </div>
    </>
  );
}
