import { useState } from "react";
import { useDispatch } from "react-redux";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import { useQuery, useMutation } from "react-query";
import { getBanners, updateBanner } from "../../../services/api/banners";
import { notifyLoading, notifyUpdate } from "../../../utilities/utils";
import Loader from "../../../components/Loader";
import ErrorPortal from "../../../components/ErrorPortal/ErrorPortal";
import { setBannerToEdit } from "../bannerSlice";

export default function Lists() {
  let announcementBanners = [];
  let errorBanners = [];
  const { status, data, error } = useQuery("banners", () =>
    getBanners({
      filters: {
        search: "",
      },
    })
  );
  if (data) {
    announcementBanners = data.banners
      .filter((banner) => banner.type === "announcement")
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    errorBanners = data.banners
      .filter((banner) => banner.type === "error")
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
  }

  if (
    error ||
    (data && data.statusCode === 400) ||
    (status === "success" && !data)
  ) {
    return (
      <ErrorPortal
        title="Create Banners"
        message="Some error occurred while fetching the data"
      />
    );
  }

  if (status === "loading")
    return (
      <div className="column gridFlow">
        <h1>List of Banners</h1>
        <div>
          <Loader />
        </div>
      </div>
    );

  return (
    <div className="column gridFlow">
      <h1>List of Banners</h1>
      <div className="gridFlow">
        <div className="items gridFlow update">
          <h2 className="announcementBanner">Announcements</h2>
          {announcementBanners.map((banner) => (
            <div key={banner._id}>
              <Banner
                name={banner.name}
                isActive={banner.isActive}
                id={banner._id}
                option="announcements"
                banner={banner}
              />
            </div>
          ))}
          <h2 className="errorBanner">Errors</h2>
          {errorBanners.map((banner) => (
            <div key={banner._id}>
              <Banner
                name={banner.name}
                isActive={banner.isActive}
                id={banner._id}
                option="errors"
                banner={banner}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Banner({ banner, name, isActive, id, option }) {
  let toastId;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(isActive);
  const { mutate } = useMutation(updateBanner, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update", "error");
    },
  });

  const handleSwitch = () => {
    mutate({
      _id: id,
      update: { isActive: !checked },
    });
    setChecked(!checked);
  };

  const handleEdit = () => {
    dispatch(setBannerToEdit({ bannerToEdit: banner, editMode: true }));
  };

  return (
    <>
      <label htmlFor={name}>{name}</label>
      <div style={{ display: "flex" }}>
        <SwitchButton
          type="checkbox"
          id={name}
          option={option}
          toggled={checked}
          onClick={handleSwitch}
        />
        {/* <img
          className="controlIcon"
          src="/assets/images/trashIcon.svg"
          alt="trashIcon"
          width={28}
          height={28}
        /> */}
        <img
          className="controlIcon"
          src="/assets/images/editIcon.svg"
          alt="editIcon"
          width={28}
          height={28}
          onClick={handleEdit}
        />
      </div>
    </>
  );
}
