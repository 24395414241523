import ReactTooltip from "react-tooltip";

export default function CreditsTypeCell({ credits, decreased }) {
  return (
    <td>
      <div className="creditsCell">
        {credits !== null &&
          credits.workEmailCredits !== null &&
          credits.workEmailCredits !== 0 && (
            <div
              className="creditsTag"
              data-tip="workEmailCredits"
              data-for="workEmailCredits"
              style={{
                color: "rgb(77, 88, 243)",
                backgroundColor: "rgba(77, 88, 243,0.1)",
              }}
            >
              <ReactTooltip id="workEmailCredits" place="top" />
              <img
                src="/assets/images/work-email-credits.svg"
                alt="work email"
              />
              {decreased !== undefined
                ? decreased
                  ? `-${credits.workEmailCredits}`
                  : `+${credits.workEmailCredits}`
                : credits.workEmailCredits}
            </div>
          )}
        {credits !== null &&
          credits.phoneCredits !== null &&
          credits.phoneCredits !== 0 && (
            <div
              className="creditsTag"
              data-tip="phoneCredits"
              data-for="phoneCredits"
              style={{
                color: "rgb(14, 183, 99",
                backgroundColor: "rgba(14, 183, 99,0.1)",
              }}
            >
              <ReactTooltip id="phoneCredits" place="top" />
              <img src="/assets/images/phone-credits.svg" alt="phone" />
              {decreased !== undefined
                ? decreased
                  ? `-${credits.phoneCredits}`
                  : `+${credits.phoneCredits}`
                : credits.phoneCredits}
            </div>
          )}
        {credits !== null &&
          credits.personalEmailCredits !== null &&
          credits.personalEmailCredits !== 0 && (
            <div
              className="creditsTag"
              data-tip="personalEmailCredits"
              data-for="personalEmailCredits"
              style={{
                color: "rgb(255, 32, 110)",
                backgroundColor: "rgba(255, 32, 110,0.1)",
              }}
            >
              <ReactTooltip id="personalEmailCredits" place="top" />
              <img src="/assets/images/direct-email-credits.svg" alt="email" />
              {decreased !== undefined
                ? decreased
                  ? `-${credits.personalEmailCredits}`
                  : `+${credits.personalEmailCredits}`
                : credits.personalEmailCredits}
            </div>
          )}
        {credits !== null &&
          credits.exportCredits !== null &&
          credits.exportCredits !== 0 && (
            <div
              className="creditsTag"
              data-tip="exportCredits"
              data-for="exportCredits"
              style={{
                color: "rgb(177, 73, 255)",
                backgroundColor: "rgba(177, 73, 255,0.1)",
              }}
            >
              <ReactTooltip id="exportCredits" place="top" />
              <img src="/assets/images/export-credits.svg" alt="export" />
              {decreased !== undefined
                ? decreased
                  ? `-${credits.exportCredits}`
                  : `+${credits.exportCredits}`
                : credits.exportCredits}
            </div>
          )}
        {credits !== null &&
          credits.legacyCredits !== null &&
          credits.legacyCredits !== 0 && (
            <div
              className="creditsTag"
              data-tip="legacyCredits"
              data-for="legacyCredits"
              style={{
                color: "rgb(119, 119, 119)",
                backgroundColor: "rgba(119, 119, 119,0.1)",
              }}
            >
              <ReactTooltip id="legacyCredits" place="top" />
              <img src="/assets/images/legacy-credits.svg" alt="legacy" />
              {decreased !== undefined
                ? decreased
                  ? `-${credits.legacyCredits}`
                  : `+${credits.legacyCredits}`
                : credits.legacyCredits}
            </div>
          )}
      </div>
    </td>
  );
}
