import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setOrgId } from "../../orgIdSlice";
import {
  getOrgDetails,
  suspendOrg,
  unsubscribeOrg,
  deleteMember,
  cancelInvitation,
  renewPlan,
} from "../../../../services/api/api";
import { notifyLoading, notifyUpdate } from "../../../../utilities/utils";
import { useState, useEffect } from "react";

export default function useOrganization() {
  let toastId;
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showSuspendPlanModal, setShowSuspendPlanModal] = useState(false);
  const showDeleteMemberModal = useSelector(
    (state) => state.deleteMember.showDeleteMemberModal
  );
  const showCancelInvitationModal = useSelector(
    (state) => state.cancelInvitation.showCancelInvitationModal
  );
  const params = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data, status, error } = useQuery(["orgDetails", params], async () =>
    getOrgDetails(params)
  );

  useEffect(() => {
    if (data) {
      dispatch(setOrgId({ organizationId: data.organization.organizationId }));
    }
  }, [data]);

  const cancelInvitationMutation = useMutation(cancelInvitation, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetails");
      res
        ? notifyUpdate(toastId, "Canceled Successfully", "success")
        : notifyUpdate(toastId, "Failed to Cancel", "error");
    },
    onError: () => notifyUpdate(toastId, "Failed to Cancel", "error"),
  });

  const deleteMemberMutation = useMutation(deleteMember, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetailsMembers");
      res
        ? notifyUpdate(toastId, "Deleted Successfully", "success")
        : notifyUpdate(toastId, "Failed to Delete", "error");
    },
    onError: () => notifyUpdate(toastId, "Failed to Delete", "error"),
  });

  const suspendMutation = useMutation(suspendOrg, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetails");
      res
        ? notifyUpdate(toastId, "Suspended Successfully", "success")
        : notifyUpdate(toastId, "Failed to Suspend", "error");
    },
  });

  const unsubscribeMutation = useMutation(unsubscribeOrg, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetails");
      res
        ? notifyUpdate(toastId, "Unsubscribed Successfully", "success")
        : notifyUpdate(toastId, "Failed to Unsubscribe", "error");
    },
    onError: () => notifyUpdate(toastId, "Failed to Unsubscribe", "error"),
  });

  const renewPlanMutation = useMutation(renewPlan, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("orgDetails");
      res
        ? notifyUpdate(toastId, "Renewed Successfully", "success")
        : notifyUpdate(toastId, "Failed to Renew", "error");
    },
  });

  const handleRenewPlan = async (organizationId) => {
    await renewPlanMutation.mutateAsync({ organizationId });
  };

  const handleSuspend = async (organizationId, bool) => {
    await suspendMutation.mutateAsync({
      organizationId,
      suspended: bool,
    });
  };

  const handleUnsubscribe = async (organizationId) => {
    await unsubscribeMutation.mutateAsync({
      organizationId,
      onlyOneAdmin: true,
    });
  };

  return {
    states: {
      data,
      status,
      error,
      showUnsubscribeModal,
      showSuspendPlanModal,
      showDeleteMemberModal,
      showCancelInvitationModal,
      params,
    },
    setters: { setShowUnsubscribeModal, setShowSuspendPlanModal, dispatch },
    handlers: {
      handleUnsubscribe,
      handleSuspend,
      handleRenewPlan,
      deleteMemberMutation,
      cancelInvitationMutation,
    },
  };
}
