import { configureStore } from "@reduxjs/toolkit";
import orgIdReducer from "./pages/Details/orgIdSlice";
import deleteMemberReducer from "./pages/Details/deleteMemberSlice";
import bannerReducer from "./pages/Banners/bannerSlice";
import cancelInvitationSlice from "./pages/Details/common/InvitationsList/cancelInvitationSlice";
import memberToRestoreReducer from "./pages/RestoreOrganizations/memberToRestoreSlice";

export default configureStore({
  reducer: {
    orgId: orgIdReducer,
    deleteMember: deleteMemberReducer,
    cancelInvitation: cancelInvitationSlice,
    banner: bannerReducer,
    memberToRestore: memberToRestoreReducer,
  },
});
