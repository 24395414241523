import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  getRewardCode,
  updateRewardCode,
} from "../../../services/api/rewardCode";
import { notifyLoading, notifyUpdate } from "../../../utilities/utils";
import Loader from "../../../components/Loader";
import ErrorPortal from "../../../components/ErrorPortal/ErrorPortal";

function Code({ code }) {
  let toastId;
  const [checked, setChecked] = useState(code.active);
  const mutation = useMutation(updateRewardCode, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Updated", "error");
    },
  });

  const handleClick = () => {
    mutation.mutate({
      rewardCodeToUpdate: { ...code, active: !checked },
      code: code.name,
    });
    setChecked(!checked);
  };

  return (
    <div>
      <label htmlFor={code.name}>{code.name}</label>
      <input
        type="checkbox"
        id={code.name}
        checked={checked}
        onChange={handleClick}
      />
    </div>
  );
}

export default function Update() {
  const { status, data, error } = useQuery("rewardCode", getRewardCode);

  if (
    error ||
    (data && data.statusCode === 400) ||
    (status === "success" && !data)
  ) {
    return (
      <ErrorPortal
        title="Create Banners"
        message="Some error occurred while fetching the data"
      />
    );
  }

  if (status === "loading")
    return (
      <div className="column gridFlow">
        <h1>Update Reward Code</h1>
        <div>
          <Loader />
        </div>
      </div>
    );

  return (
    <div className="column gridFlow">
      <h1>Update Reward Code</h1>
      <div className="gridFlow">
        <div className="items gridFlow update">
          {data.map((code) => (
            <Code key={code._id} code={code} />
          ))}
        </div>
      </div>
    </div>
  );
}
