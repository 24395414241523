function MainWrapper({ children, onSubmit, title, styleId }) {
  return (
    <div id={styleId}>
      <h1>{title}</h1>
      <div className={"update_plan_container"}>
        <form onSubmit={onSubmit}>{children}</form>
      </div>
    </div>
  );
}

function SectionWrapper({ children, title, className }) {
  return (
    <>
      {title && <h2>{title} :</h2>}
      <div className={"form-wrapper first " + className}>{children}</div>
    </>
  );
}

function GroupWrapper({ children }) {
  return <div className={"form-container"}>{children}</div>;
}

export { MainWrapper, SectionWrapper, GroupWrapper };
