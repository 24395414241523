import { useEffect } from "react";
import CustomInput from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

export default function SearchOrganization({ searchInputRef, handleSearch }) {
  useEffect(() => {
    const searchOnEnterKey = (e) => (e.key === "Enter" ? handleSearch() : null);
    document.body.addEventListener("keydown", searchOnEnterKey);
    return () => {
      document.body.removeEventListener("keydown", searchOnEnterKey);
    };
  }, [handleSearch]);

  return (
    <div className="searchContainer">
      <CustomInput ref={searchInputRef} placeHolder="Workspace ID" />
      <Button handleClick={handleSearch}>Search</Button>
    </div>
  );
}
