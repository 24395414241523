import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCountry, notifyLoading, notifyUpdate } from "../../utilities/utils";
import { getOrgPlan, updateOrgPlan } from "../../services/api/api";

export default function useOrganizationPlan() {
  let toastId;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const email = queryParams.get("email");
  const { status, data, error } = useQuery(["orgPlan", params], () =>
    getOrgPlan(params)
  );
  const mutation = useMutation(updateOrgPlan, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update", "error");
      setTimeout(() => {
        queryClient.invalidateQueries("orgPlan");
        navigate(`/Details/OrganizationDetails/${email}`);
      }, 5500);
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Update", "error");
    },
  });

  const [isLoading, setIsLoading] = useState();
  const [state, setState] = useState();
  const [originalState, setOriginalState] = useState();
  const [originalPermissionsWithActions, setOriginalPermissionsWithActions] =
    useState();
  const [
    originalPermissionsWithoutActions,
    setOriginalPermissionsWithoutActions,
  ] = useState();
  const [permissionsWithActions, setPermissionsWithActions] = useState();
  const [permissionsWithoutActions, setPermissionsWithoutActions] = useState();
  const [reset, setReset] = useState(false);
  const [offlineUser, setOfflineUser] = useState();

  useEffect(() => {
    if ((status === "success") & !state) {
      setState({ ...data.org });
      setOriginalState({ ...data.org });
      setOriginalPermissionsWithActions(data.permissionsWithActions);
      setOriginalPermissionsWithoutActions(data.permissionsWithoutActions);
      setPermissionsWithActions(data.permissionsWithActions);
      setPermissionsWithoutActions(data.permissionsWithoutActions);
      setOfflineUser(data.subscriptionId === "NA" ? true : false);
      setIsLoading(false);
    }
    if ((status === "loading") & !state) {
      setIsLoading(true);
    }
    if (status === "error") {
      setIsLoading(false);
    }
  }, [status]);

  const onChange = useCallback(
    (value, id, creditTypes, bulkTypes) => {
      const dataToUpdate = Object.assign({}, state);

      switch (id) {
        case "day":
        case "hour":
        case "minute":
          dataToUpdate.publicApiLimits[id] = value;
          break;

        case "publicApi":
          // eslint-disable-next-line no-case-declarations
          const publicApi = dataToUpdate.permissions.find(
            (permission) => permission.name == "PublicApi"
          );
          if (!publicApi && value)
            dataToUpdate.permissions.push({ name: "PublicApi" });
          else
            dataToUpdate.permissions = dataToUpdate.permissions.filter(
              (permission) => permission.name !== "PublicApi"
            );
          break;

        case "creditsPerSeat":
          dataToUpdate[creditTypes].perSeat = value;
          break;

        case "creditsExtra":
          dataToUpdate[creditTypes].extra = value;
          break;

        case "bulkEnrichmentLimits":
          dataToUpdate[id][bulkTypes] = value;
          break;

        default:
          dataToUpdate[id] = value;
      }
      setState(dataToUpdate);
    },
    [state]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const tempPWA = permissionsWithActions.map((p) => {
        return {
          ...p,
          actions: p.actions.filter((a) => a.checked).map((a) => a.action),
        };
      });
      const tempPWOA = permissionsWithoutActions
        .filter((p) => p.checked)
        .map((p) => {
          const { checked, ...rest } = p; // eslint-disable-line
          return rest;
        });

      const dataToSend = {
        ...state,
        countries: getCountry(state.currency),
        yearlyPricePerSeat: state.pricePerSeat,
        isDefault: true,
      };
      dataToSend.permissions = [...tempPWA, ...tempPWOA];

      mutation.mutate({
        plan: dataToSend,
        ...params,
      });
    },
    [state, permissionsWithActions, permissionsWithoutActions, mutation]
  );

  const onResetState = (e) => {
    e.preventDefault();
    setState(originalState);
    setPermissionsWithActions(originalPermissionsWithActions);
    setPermissionsWithoutActions(originalPermissionsWithoutActions);
    setReset(!reset);
  };

  return {
    states: {
      state,
      offlineUser,
      error,
      isLoading,
      reset,
      permissionsWithActions,
      permissionsWithoutActions,
    },
    setters: { setPermissionsWithActions, setPermissionsWithoutActions },
    handlers: { onResetState, onSubmit, onChange },
  };
}
