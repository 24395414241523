import { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setBannerToEdit } from "./bannerSlice";
import { createBanner, updateBanner } from "../../services/api/banners";
import { notifyLoading, notifyUpdate } from "../../utilities/utils";

export default function useBanners() {
  let toastId;
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const bannerToEdit = useSelector((state) => state.banner.bannerToEdit);
  const editMode = useSelector((state) => state.banner.editMode);
  const queryClient = useQueryClient();
  const mutation = useMutation(createBanner, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("banners");
      res
        ? notifyUpdate(toastId, "Create Successfully", "success")
        : notifyUpdate(toastId, "Failed to Create", "error");
    },
  });
  const { mutate: editMutate } = useMutation(updateBanner, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries("banners");
      res
        ? notifyUpdate(toastId, "Updated Successfully", "success")
        : notifyUpdate(toastId, "Failed to Update", "error");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let type;
    if (e.currentTarget.announcement.checked) {
      type = "announcement";
    }
    if (e.currentTarget.error.checked) {
      type = "error";
    }
    await mutation.mutateAsync({
      name: e.currentTarget.name.value,
      type,
      descriptionText: e.currentTarget.description.value,
      buttonText: e.currentTarget.buttonText.value,
      link: e.currentTarget.link.value,
      isActive: e.currentTarget.isActive.checked,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    let type;
    if (e.currentTarget.announcement.checked) {
      type = "announcement";
    }
    if (e.currentTarget.error.checked) {
      type = "error";
    }
    await editMutate({
      _id: bannerToEdit._id,
      update: {
        name: e.currentTarget.name.value,
        type,
        descriptionText: e.currentTarget.description.value,
        buttonText: e.currentTarget.buttonText.value,
        link: e.currentTarget.link.value,
        isActive: e.currentTarget.isActive.checked,
      },
    });
  };

  return {
    states: { editMode, formRef, bannerToEdit },
    setters: { setBannerToEdit },
    handlers: { handleUpdate, handleSubmit, dispatch },
  };
}
