import { Link } from "react-router-dom";
import "./Button.css";

export default function Button({
  className,
  link,
  type,
  width,
  disabled,
  handleClick,
  children,
}) {
  if (link) {
    return (
      <Link type={type} to={link}>
        <button
          type={type}
          style={{ width, height: 60 }}
          disabled={disabled}
          className={className ? className : "btn"}
        >
          {children}
        </button>
      </Link>
    );
  }
  return (
    <button
      type={type}
      style={{ width }}
      disabled={disabled}
      className={className ? className : "btn"}
      onClick={
        handleClick
          ? (e) => {
              handleClick(e);
            }
          : null
      }
    >
      {children}
    </button>
  );
}
