import { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getCreditsUsage } from "../../services/api/creditsUsage";
import {
  convertDateISO,
  mapCreditTypes,
  mapSources,
} from "../../utilities/utils";
// import data from "./mockedData.json";

export default function useCreditsUsage(pageTitle) {
  const tableRef = useRef();
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const email = queryParams.get("email");
  const organizationId = queryParams.get("organizationId");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  let payload;
  if (pageTitle === "Personal Credits Usage") {
    payload = {
      email,
      byUser: true,
      byOrganization: false,
      page: currentPage,
      resultPerPage: 10,
      creditTypes: [
        "workEmailCredits",
        "phoneCredits",
        "personalEmailCredits",
        "exportCredits",
        "legacyCredits",
      ],
    };
  } else if (filter) {
    payload = {
      email,
      byUser: false,
      byOrganization: true,
      page: currentPage,
      resultPerPage: 10,
      startDate: convertDateISO(filter.startDate),
      endDate: convertDateISO(filter.endDate),
      creditTypes: mapCreditTypes(filter.credits),
      sources: mapSources(filter.sources),
    };
  } else {
    payload = {
      email,
      byUser: false,
      byOrganization: true,
      page: currentPage,
      resultPerPage: 10,
      creditTypes: [
        "workEmailCredits",
        "phoneCredits",
        "personalEmailCredits",
        "exportCredits",
        "legacyCredits",
      ],
    };
  }

  const scrollToElement = () => {
    tableRef.current
      ? tableRef.current.scrollIntoView({ behavior: "smooth" })
      : null;
  };

  useEffect(() => {
    if (filter) {
      payload = {
        email,
        byUser: false,
        byOrganization: true,
        page: currentPage,
        resultPerPage: 10,
        startDate: convertDateISO(filter.startDate),
        endDate: convertDateISO(filter.endDate),
        creditTypes: mapCreditTypes(filter.credits),
        sources: mapSources(filter.sources),
      };
    }
  }, [filter]);

  useEffect(() => {
    const page = parseInt(queryParams.get("page"));

    if (page && page !== currentPage) {
      setCurrentPage(page);
      scrollToElement();
    }
    if (!page) {
      setCurrentPage(1);
    }
  }, [currentPage, queryParams]);

  const onPageChange = (page) => {
    navigate(`?email=${email}&page=${page}`);
    setCurrentPage(page);
    scrollToElement();
  };

  const { status, data, error } = useQuery(
    ["creditsUsage", currentPage, filter],
    () => getCreditsUsage(payload)
  );

  return {
    states: {
      tableRef,
      status,
      data,
      error,
      currentPage,
      filter,
      organizationId,
    },
    setters: { setFilter },
    handlers: {
      onPageChange,
    },
  };
}
