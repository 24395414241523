import "./PermissionsCell.css";

export default function PermissionsCell({ permissions, members }) {
  return (
    <td id="PermissionsCell">
      {permissions.map((permission, i) =>
        permission.name !== "Administration" ? (
          <div
            key={members ? `members${permission.name}` : permission._id}
            className="permissionContainer"
          >
            <span className="permissionType">{permission.name}:</span>
            <div className="actionsContainer">
              {permission.actions.map((action) => (
                <span
                  key={
                    members
                      ? `members${action}${i}`
                      : `${permission._id}${action}`
                  }
                  className="actionPill"
                >
                  {action}
                </span>
              ))}
            </div>
          </div>
        ) : null
      )}
    </td>
  );
}
