import { useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { notifyLoading, notifyUpdate } from "../../../../utilities/utils";
import "./AttachModal.css";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import Loader from "../../../../components/Loader";
import {
  getPlans,
  getCoupons,
  attachOrgPlan,
} from "../../../../services/api/api";

export default function AttachPlanModal({ orgId, setIsOpen, email }) {
  let toastId;
  const planRef = useRef(null);
  const couponRef = useRef(null);
  const numberOfMonthsRef = useRef(null);
  const numberOfUsersRef = useRef(null);
  const phoneCreditRef = useRef(null);
  const emailCreditRef = useRef(null);
  const exportCreditRef = useRef(null);
  const queryClient = useQueryClient();
  const { data: planData, status: planStatus } = useQuery(["plans"], getPlans);
  const { data: couponData, status: couponStatus } = useQuery(
    ["coupons"],
    getCoupons
  );

  const mutation = useMutation(attachOrgPlan, {
    onMutate: () => {
      toastId = notifyLoading("Processing");
    },
    onSuccess: (res) => {
      res
        ? notifyUpdate(toastId, "Attached Successfully", "success")
        : notifyUpdate(toastId, "Failed to Attach", "error");
      queryClient.invalidateQueries("orgDetails");
      setIsOpen(false);
    },
    onError: () => {
      notifyUpdate(toastId, "Failed to Attach", "error");
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    let dataToSend = null;
    planData.map((plan) => {
      if (plan.name === planRef.current.value) {
        dataToSend = {
          planId: plan.planId,
          ...orgId,
          email: email,
          numberOfMonths: numberOfMonthsRef.current.value,
          numberOfSeats: numberOfUsersRef.current.value,
          phoneCreditsExtra: Number(phoneCreditRef.current.value),
          personalEmailCreditsExtra: Number(emailCreditRef.current.value),
          exportCreditsExtra: Number(exportCreditRef.current.value),
          legacyExtra: 0,
          coupon:
            couponRef.current.value === "-- Select a Coupon --"
              ? ""
              : couponRef.current.value,
        };
      }
    });
    await mutation.mutateAsync(dataToSend);
  };

  if (planStatus === "loading" || couponStatus === "loading") {
    return <Loader />;
  }

  return (
    <form id="attachModal">
      <h1>Change Organization Plan</h1>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          placeholder="user@example.com"
          value={email}
          disabled
        />
      </div>
      <div>
        <label htmlFor="months">Number of months:</label>
        <input ref={numberOfMonthsRef} id="months" placeholder="0" />
      </div>
      <div>
        <label htmlFor="users">Number of Users:</label>
        <input ref={numberOfUsersRef} id="users" placeholder="0" />
      </div>
      <div className="extraCreditContainer">
        <label>Extra Credits:</label>
        <div className="extraCreditFieldContainer">
          <Input key="PhoneInput" label={"Phone"} ref={phoneCreditRef} />
          <Input
            key="DirectEmailInput"
            label={"Direct Email"}
            ref={emailCreditRef}
          />
          <Input key="ExportInput" label={"Export"} ref={exportCreditRef} />
        </div>
      </div>
      <div>
        <label htmlFor="plan">Plan:</label>
        <select ref={planRef} name="plan" id="plan">
          {planData.map((plan, i) => {
            return (
              <option key={`plan_${i}_${plan.planId}`} value={plan.name}>
                {plan.name}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label htmlFor="plan">Coupons:</label>
        <select ref={couponRef} name="coupon" id="coupon">
          <option value="-- Select a Coupon --">-- Select a Coupon --</option>
          {couponData.map((coupon, i) => {
            return (
              <option key={`coupon_${i}_${coupon._id}`} value={coupon.code}>
                {coupon.code}
              </option>
            );
          })}
        </select>
      </div>
      <div style={{ textAlign: "center", paddingTop: 20 }}>
        <Button type="submit" width="150px" handleClick={handleSave}>
          Save
        </Button>
      </div>
    </form>
  );
}
