import { useEffect } from "react";
import CustomInput from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";

export default function SearchMembers({
  searchInputRef,
  handleSearch,
  maxAdmins,
  totalAdmins,
}) {
  useEffect(() => {
    const searchOnEnterKey = (e) => (e.key === "Enter" ? handleSearch() : null);
    document.body.addEventListener("keydown", searchOnEnterKey);
    return () => {
      document.body.removeEventListener("keydown", searchOnEnterKey);
    };
  }, [handleSearch]);

  return (
    <div className="searchWrapper">
      <div
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          marginBottom: "1rem",
          color: `${totalAdmins > maxAdmins ? "firebrick" : "inherit"}`,
        }}
      >
        {`${totalAdmins}/${maxAdmins} admin seats`}
      </div>
      <div className="searchContainer">
        <CustomInput ref={searchInputRef} />
        <Button handleClick={handleSearch}>Search</Button>
      </div>
    </div>
  );
}
