import { createSlice } from "@reduxjs/toolkit";

export const cancelInvitationSlice = createSlice({
  name: "cancelInvitation",
  initialState: {
    showCancelInvitationModal: false,
  },
  reducers: {
    setInvitationToCancel: (state, action) => {
      state.inviteeEmail = action.payload.inviteeEmail;
      state.organizationOwner = action.payload.organizationOwner;
      state.orgId = action.payload.organizationId;
      state.showCancelInvitationModal = action.payload.showCancelInvitationModal;
    },
  },
});

export const { setInvitationToCancel } = cancelInvitationSlice.actions;

export default cancelInvitationSlice.reducer;
